import { FileImageOutlined } from '@ant-design/icons';
import React, { memo } from 'react';
import Lightbox from 'react-images-extended';

import { Avatar } from 'components';
import { useToggle } from 'hooks';

const Image = ({
  src,
  size,
  shape = 'square',
  baseIcon = <FileImageOutlined />,
  noImageText = 'Sem imagem',
  ...props
}) => {
  const [isOpen, toggle] = useToggle();

  return (
    <>
      <div
        style={{ cursor: src ? 'pointer' : 'default' }}
        onClick={src ? () => toggle() : undefined}
      >
        <Avatar
          icon={baseIcon}
          src={src}
          shape={shape}
          size={size}
          title={src ? undefined : noImageText}
          {...props}
        />
      </div>
      <Lightbox
        images={src ? [{ src }] : []}
        isOpen={isOpen}
        onClose={toggle}
        closeButtonTitle="Fechar (esc)"
        zoomable
        rotatable
      />
    </>
  );
};

export default memo(Image);
