import { Icon } from '@mdi/react';
import { Typography } from 'antd';
import React from 'react';

import { useTheme } from 'hooks';

const IconTitled = ({ icon, antIcon = false, title, ...props }) => {
  const { dispatch, ...filteredProps } = props;
  const theme = useTheme();

  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {antIcon ? (
          { icon }
        ) : (
          <Icon
            path={icon}
            size={1}
            horizontal
            {...filteredProps}
            color={theme.primaryColor}
          />
        )}
        <Typography.Text
          strong
          style={{ paddingLeft: '10px', marginBottom: '0px' }}
        >
          {title}
        </Typography.Text>
      </div>
    </>
  );
};

export default IconTitled;
