export const APPLICATION_CONFIGS = {
  applicationId: localStorage.getItem('applicationId'),
  baseUrl: localStorage.getItem('baseUrl'),
  BASE_PREFIX: '/admins',
  AUTHENTICATION_EXPIRATION_TIME: 1,
  PASSPRASE: 'mps-passphrase',
};

export const setBaseConfig = () => {
  localStorage.removeItem('baseUrl');
  localStorage.removeItem('applicationId');

  localStorage.setItem('baseUrl', process.env.REACT_APP_API_BASEURL);
  localStorage.setItem('applicationId', process.env.REACT_APP_ID);
};
