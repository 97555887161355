import {
  LANGUAGE_CONFIG_PT_AO,
  LANGUAGE_CONFIG_PT_BR,
} from 'configs/languages';

export const getAppLanguage = language => {
  switch (language) {
    case 'pt-BR':
      return LANGUAGE_CONFIG_PT_BR;

    case 'pt-AO':
      return LANGUAGE_CONFIG_PT_AO;

    default:
      return LANGUAGE_CONFIG_PT_BR;
  }
};

export const inputCurrencyLanguage = language => {
  const {
    numbro: {
      currency: { position, symbol },
      delimiters: { decimal },
      currencyFormat: { mantissa },
    },
  } = getAppLanguage(language);
  return {
    [position]: symbol,
    decimalSeparator: decimal,
    precision: mantissa,
  };
};
