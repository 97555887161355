import CryptoJS from 'crypto-js';

import { APPLICATION_CONFIGS } from 'configs';

export const encrypt = (key, passphrase = APPLICATION_CONFIGS.PASSPRASE) =>
  CryptoJS.AES.encrypt(key, passphrase);

export const decrypt = (hash, passphrase = APPLICATION_CONFIGS.PASSPRASE) => {
  if (!hash) return hash;

  const bytes = CryptoJS.AES.decrypt(hash, passphrase);

  return bytes.toString(CryptoJS.enc.Utf8);
};
