import notificationActionTypes from './constants';

export const setFilters = (filters) => ({
  type: notificationActionTypes.SET_FILTER,
  filters,
});

export const clearFilters = () => ({
  type: notificationActionTypes.CLEAR_FILTER,
});

export const setTableConfigs = (tableConfigs) => ({
  type: notificationActionTypes.SET_TABLE_CONFIGS,
  tableConfigs,
});

export const fetchNotifications = () => ({
  type: notificationActionTypes.FETCH_NOTIFICATIONS,
});

export const fetchNotificationsSuccess = (notifications, totalPage) => ({
  type: notificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS,
  response: { notifications, totalPage },
});

export const sendNotificationUsers = (formOptions) => ({
  type: notificationActionTypes.SEND_NOTIFICATION_USERS,
  formOptions,
});

export const queryUser = (query) => ({
  type: notificationActionTypes.QUERY_USER,
  query,
});

export const queryUserSuccess = (searchedUser) => ({
  type: notificationActionTypes.QUERY_USER_SUCCESS,
  searchedUser,
});
