import styled from 'styled-components';

export const FormTitleContainer = styled.h2`
  font-size: 24px;
  font-weight: 500;
`;

export const LabelContainer = styled.p`
  font-family: inherit;
  font-weight: 400;
  font-size: 17px;
  color: #707070;
  margin: 10px 0 0 0;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UploadCoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  input[type='file'] {
    display: none;
  }

  label {
    font-size: 17px;
    color: #707070;
    width: 140px;
    height: 150px;
    radius: 10px;
    border: 1px dotted #707070;
    border-radius: 8px;
    background-color: #efefef;
    cursor: pointer;
    transition: 0.2s;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
      background-color: #d7d7d7;
    }
  }
`;

export const UploadPdfContainer = styled.div`
  display: flex;
  flex-direction: column;

  input[type='file'] {
    display: none;
  }

  label {
    font-size: 17px;
    font-family: inherit;
    color: #fff;
    width: 140px;
    height: 32px;
    radius: 10px;
    border-radius: 8px;
    background-color: #e36926;
    cursor: pointer;
    transition: 0.2s;

    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #b6541e;
    }
  }
`;

export const UploadedListContainer = styled.div`
  color: #e36926;
  font-size: 15px;
  border-bottom: 1px solid #e36926;
  padding: 10px 0 2px 0;
  transition: 0.2s;

  display: flex;
  align-items: center;
  justify-content: space-between;

  &:hover {
    color: #b6541e;
  }
`;
