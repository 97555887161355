import req from 'utils/request';
import { CloseOutlined, MessageOutlined } from '@ant-design/icons';
import { Button, Tooltip, Row, Col } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { capitalize } from 'lodash';
import { Form, Input, ButtonGroup, SubmitButton, Select } from 'components';
import { useDispatch, useSelector } from 'react-redux';
import { queryUser } from '../../../containers/Notifications/actions';
import { selectSearchedUsers } from '../../../containers/Notifications/selectors';
import { LIST_NOTIFICATIONS_OPTIONS } from 'utils/layout/selects';

const messageSubmited = { isVerified: false, isActive: false };

export const BodyNotification = ({
  modalTitle,
  onSubmitNotification,
  optionsFilter = false,
  loading,
  hasFloatterButton = true,
  render: [Modal, toggleModal],
}) => {
  const dispatch = useDispatch();
  const queriedUsers = useSelector(selectSearchedUsers());

  const [selectedUser, setValue] = useState(undefined),
    [selectedValue, setSelectedValue] = useState(null),
    [searchedUsers, setSearchedUsers] = useState([]);

  useEffect(() => {
    setSearchedUsers(queriedUsers);
  }, [queriedUsers]);

  async function fetch(query) {
    dispatch(queryUser(query));
  }

  const handleSearch = async query => {
    if (query) {
      await fetch(query);
    } else {
      setSearchedUsers([]);
    }
  };

  const handleChange = selectedUser => {
    setValue(selectedUser);
  };

  const options = searchedUsers.map(d => (
    <Select.Option key={d.id}>
      <span>{capitalize(d.name.toLowerCase())}</span>
    </Select.Option>
  ));

  const notifOptions = LIST_NOTIFICATIONS_OPTIONS.map((d, index) => (
    <Select.Option key={index} value={d.value}>
      <span>{capitalize(d.label.toLowerCase())}</span>
    </Select.Option>
  ));

  const selectHandler = selected => {
    setSelectedValue(selected);
  };

  return (
    <>
      {hasFloatterButton && (
        <Tooltip title="Enviar notificação">
          <Button
            icon={<MessageOutlined />}
            type="primary"
            size="large"
            shape="round"
            disabled={loading}
            style={{
              float: 'right',
              borderRadius: '0',
              backgroundColor: '#F9B234',
            }}
            onClick={() => toggleModal()}
          >
            Enviar notificação
          </Button>
        </Tooltip>
      )}

      <Modal title={modalTitle} footer={null}>
        <>
          <Row gutter={[20, 20]}>
            <Formik
              initialValues={messageSubmited}
              validateOnMount
              onSubmit={(values, { setSubmitting, resetForm }) => {
                const newValue = {
                  target: values.target,
                  title: values.title,
                  text: values.text,
                  userId: selectedUser,
                };
                if (onSubmitNotification) onSubmitNotification(newValue);
                setSubmitting(false);
                toggleModal();
                resetForm();
              }}
            >
              <Form layout="vertical" style={{ width: '100%' }}>
                {optionsFilter && (
                  <>
                    <Row gutter={[20, 20]}>
                      <Col md={24}>
                        <Form.Item required name="target" label="Enviar para">
                          <Select
                            name="target"
                            placeholder="Escolha um grupo para enviar notificação"
                            style={{ width: '100%' }}
                            onChange={e => selectHandler(e)}
                          >
                            {notifOptions}
                          </Select>

                          {selectedValue && selectedValue === 'USER' && (
                            <Row
                              gutter={[20, 20]}
                              style={{ marginTop: '10px' }}
                            >
                              <Col md={24}>
                                <Form.Item
                                  className="form__item"
                                  required
                                  name="userId"
                                >
                                  <Select
                                    name="userId"
                                    className="ant-select-selector search-select"
                                    showSearch
                                    value={selectedUser}
                                    defaultValue={selectedUser}
                                    defaultActiveFirstOption={false}
                                    placeholder="Pesquisar usuários"
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={handleSearch}
                                    onChange={handleChange}
                                    notFoundContent={null}
                                  >
                                    {options}
                                  </Select>
                                </Form.Item>
                              </Col>
                            </Row>
                          )}
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}

                <Row guuter={[20, 20]}>
                  <Col md={24}>
                    <Form.Item
                      required
                      name="title"
                      label="Título da notificação"
                    >
                      <Input
                        name="title"
                        placeholder="Insira aqui o título da notificação"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row guuter={[20, 20]}>
                  <Col md={24}>
                    <Form.Item
                      required
                      name="text"
                      label="Conteúdo da notificação"
                    >
                      <Input.TextArea
                        name="text"
                        rows={6}
                        placeholder="Insira aqui a mensagem da notificação"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Col md={24} justify="end">
                  <ButtonGroup>
                    <Button
                      icon={<CloseOutlined />}
                      key="cancelNotification"
                      onClick={() => {
                        toggleModal();
                      }}
                    >
                      Cancelar
                    </Button>
                    <SubmitButton key="newNotification" showValidationErrors>
                      Enviar
                    </SubmitButton>
                  </ButtonGroup>
                </Col>
              </Form>
            </Formik>
          </Row>
        </>
      </Modal>
    </>
  );
};

export default BodyNotification;
