import {
  SET_FILTER,
  CLEAR_FILTER,
  SET_TABLE_CONFIGS,
  FETCH_CLIENTS,
  FETCH_CLIENTS_SUCCESS,
} from './constants';

export const setFilters = filters => ({
  type: SET_FILTER,
  filters,
});

export const clearFilters = () => ({
  type: CLEAR_FILTER,
});

export const setTableConfigs = tableConfigs => ({
  type: SET_TABLE_CONFIGS,
  tableConfigs,
});

export const fetchClients = () => ({
  type: FETCH_CLIENTS,
});

export const fetchClientsSuccess = (clients, totalClients) => ({
  type: FETCH_CLIENTS_SUCCESS,
  response: { clients, totalClients },
});
