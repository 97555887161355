import { Card as WrapperCard, Collapse } from 'antd';
import React from 'react';

export const { Grid, Meta } = WrapperCard;
const { Panel } = Collapse;

const filteredProps = props => {
  if (props.title) {
    const { title, ...newProps } = props;
    return newProps;
  }

  return props;
};

const Card = ({ collapsible, children, ...props }) => (
  <>
    {collapsible ? (
      <Collapse
        expandIconPosition="right"
        style={{ marginBottom: '10px', flex: '1', backgroundColor: '#fff' }}
      >
        <Panel header={props.title} key="1">
          <WrapperCard bordered={false} {...filteredProps(props)}>
            {children}
          </WrapperCard>
        </Panel>
      </Collapse>
    ) : (
      <WrapperCard {...props}>{children}</WrapperCard>
    )}
  </>
);

export default Card;
