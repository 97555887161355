import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';

import { Input } from 'components';

const ChangePasswordAdmin = ({ name }) => {
  const {
    values: { changePassword },
    setFieldValue,
  } = useFormikContext();

  const [disabled, setDisabled] = useState(false);
  const [password, setPassword] = useState();

  useEffect(() => {
    if (changePassword) {
      setDisabled(false);
    } else {
      setDisabled(false);
      setPassword();
    }
  }, [changePassword]);

  useEffect(() => {
    setFieldValue(name, password);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const changeValue = value => {
    setPassword(value);
  };

  return (
    <>
      <Input.Password
        id="inputPassword"
        name={name}
        placeholder="Insira a senha do novo administrador"
        disabled={disabled}
        onBlur={value => changeValue(value.target.value)}
      />
    </>
  );
};

export default ChangePasswordAdmin;
