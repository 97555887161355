import { MoreOutlined } from '@ant-design/icons';
import { Tooltip, Button, Dropdown, Menu, Popconfirm } from 'antd';
import React from 'react';

import { MenuLink } from 'components/layout/SideBar/Styles';

const Confirmation = ({ confirm, children, onConfirm, ...props }) => (
  <>
    {(confirm && (
      <Popconfirm
        title={confirm.title}
        okText={confirm.ok}
        cancelText={confirm.cancel}
        onCancel={() => {}}
        onConfirm={onConfirm}
        {...props}
      >
        {children}
      </Popconfirm>
    )) ||
      children}
  </>
);

const DropMenu = ({ actions, onAction }) => {
  const handleAction = action => {
    if (onAction)
      onAction({
        type: action.type,
        params: action.params,
        documents: action.documents,
      });
  };

  return (
    <Menu>
      {actions &&
        actions.map((action, index) => (
          <Menu.Item key={index} icon={action.icon}>
            {typeof action.action === 'string' && (
              <MenuLink to={action.action}>
                <span style={{ marginLeft: '10px' }}>{action.name}</span>
              </MenuLink>
            )}
            {action.haveForm && (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                onClick={() => handleAction(action.action)}
              >
                <span style={{ marginLeft: '10px' }}>{action.name}</span>
              </div>
            )}
            {action.haveToConfirm && (
              <Confirmation
                confirm={action.haveToConfirm}
                onConfirm={() => handleAction(action.action)}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ marginLeft: '10px' }}>{action.name}</span>
                </div>
              </Confirmation>
            )}
          </Menu.Item>
        ))}
    </Menu>
  );
};

const ShortAction = ({ actions, onAction }) => {
  const handleAction = action => {
    if (onAction)
      onAction({
        type: action.type,
        params: action.params,
        documents: action.documents,
      });
  };

  return (
    <div style={{ display: 'flex' }}>
      {actions &&
        actions.map((action, index) => (
          <Tooltip key={index} title={action.name}>
            {typeof action.action === 'object' && action.haveToConfirm && (
              <Confirmation
                confirm={action.haveToConfirm}
                onConfirm={() => handleAction(action.action)}
              >
                <Button
                  style={{ fontSize: '14px' }}
                  icon={action.icon}
                  type="link"
                />
              </Confirmation>
            )}
            {typeof action.action === 'string' && (
              <MenuLink to={action.action}>
                <Button
                  style={{ fontSize: '14px' }}
                  icon={action.icon}
                  type="link"
                />
              </MenuLink>
            )}
            {action.haveForm && (
              <Button
                style={{ fontSize: '14px' }}
                icon={action.icon}
                type="link"
                onClick={() => handleAction(action.action)}
              />
            )}
          </Tooltip>
        ))}
    </div>
  );
};

const LongAction = ({ actions, onAction }) => (
  <>
    <Dropdown
      overlay={<DropMenu actions={actions} onAction={onAction} />}
      placement="bottomRight"
    >
      <Button shape="circle" icon={<MoreOutlined />} />
    </Dropdown>
  </>
);

const ActionGroup = ({ actions, onAction, dropdown }) => (
  <>
    {actions.length < 3 && !dropdown ? (
      <ShortAction actions={actions} onAction={onAction} />
    ) : (
      <LongAction actions={actions} onAction={onAction} />
    )}
  </>
);

export default ActionGroup;
