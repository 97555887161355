import styled from 'styled-components';

export const DivButton = styled.div`
  text-align: center;
  margin-top: 12;
  height: 32;
  line-height: 32px;
  display: flex;
  float: right;
`;
