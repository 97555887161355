/* eslint-disable func-names */
import { CpfValidator } from '@usemobile_br/usekit';
import * as Yup from 'yup';

import { CPF_INVALID_MESSAGE } from 'validations/messages';

Yup.addMethod(Yup.string, 'cpf', function(message) {
  return this.test('cpf', message || CPF_INVALID_MESSAGE, function(value) {
    const { path, createError } = this;
    return (
      new CpfValidator().isValid(value) ||
      createError({ path, message: message || CPF_INVALID_MESSAGE })
    );
  });
});

function equalTo(ref, msg) {
  return this.test({
    name: 'equalTo',
    exclusive: false,
    message: msg,
    params: {
      reference: ref.path,
    },
    test(value) {
      return value === this.resolve(ref);
    },
  });
}

Yup.addMethod(Yup.string, 'equalTo', equalTo);
