import { trim } from 'lodash';
import numbro from 'numbro';
import moment from 'moment';

export const coallesce = value => value || null;

export const normalizeString = str => trim(str);

export const formatToCurrency = value => numbro(value || 0).formatCurrency();

export const moduleValue = value => Math.abs(value);

export const isEditing = checkedValue => !!checkedValue;

export const compareValues = (key, order = 'asc') =>
  function innerSort(a, b) {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };

export const genderFriendly = ({
  text,
  gender,
  isPlural = false,
  isFemale = false,
}) =>
  `${text.slice(0, isPlural ? -2 : -1)}${
    gender === 'f' || isFemale ? 'a' : 'o'
  }${isPlural ? 's' : ''}`;

export const setEllipsis = (str, sizeString = 12, substringSize = 12) =>
  str.length > sizeString ? `${str.substring(0, substringSize)}...` : str;

export const formatDate = date => moment(date).format('DD/MM/YYYY');

export const formatInHours = date => moment(date).format('hh:mm A');

export const formatInMinutes = time => {
  return `${Math.floor(time / 60)}:${Math.floor(time % 60)} minutos`;
};

export const formatSupportNumber = supportNumber =>
  supportNumber.replace(/[+^ (\(|\))\-]/g, '');

export const handleTimezone = (date, time) => {
  const timezone = moment(`${date} ${time}`).format(),
    utc = moment.utc(timezone).format();

  return utc;
};

export const getTimezoneInHours = () =>
  (new Date().getTimezoneOffset() / 60) * -1;
