import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { ExportToCsv } from 'export-to-csv';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';

const DownloadCsv = ({
  filename,
  dataCsv,
  onDownload,
  loadingCsv,
  header,
  loadingTable,
  isReady,
  shape,
  ghost,
  title = 'Download .csv',
  icon = <DownloadOutlined />,
  ...props
}) => {
  const options = {
    fieldSeparator: ';',
    quoteStrings: '"',
    showLabels: true,
    useBom: true,
    headers: header,
    filename,
  };

  const csvExporter = new ExportToCsv(options);

  useEffect(() => {
    if (!isEmpty(dataCsv) && isReady) {
      csvExporter.generateCsv(dataCsv);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCsv, isReady]);

  return (
    <>
      <Button
        type="primary"
        shape={shape}
        icon={icon}
        ghost={ghost}
        onClick={() => onDownload()}
        loading={loadingCsv}
        disabled={loadingTable}
        {...props}
      >
        {title}
      </Button>
    </>
  );
};
export default DownloadCsv;
