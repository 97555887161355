import { Table } from 'antd';
import React from 'react';

const Tables = ({
  columns,
  dataSource,
  size = 'middle',
  pagination,
  expandedRow,
  expandedFeaturesOptions = undefined,
  ...props
}) => (
  <Table
    {...props}
    dataSource={dataSource}
    columns={columns}
    size={size}
    pagination={{
      ...pagination,
      simple: pagination?.simple ?? 'simple',
      pageSize: pagination?.pageSize ?? 10,
      current: pagination?.current ?? 1,
    }}
    scroll={{ x: '800px', scrollToFirstRowOnChange: true }}
    expandedRowRender={
      expandedRow
        ? record => expandedRow(record, expandedFeaturesOptions)
        : undefined
    }
  />
);
export default Tables;
