import moment from 'moment';

export const filterNotificationsFormat = filterOptions => {
  const { startDate, message, endDate } = filterOptions || {};
  return {
    message: message || null,
    startDate: (startDate && moment(startDate).format('YYYY-MM-DD')) || null,
    endDate: (endDate && moment(endDate).format('YYYY-MM-DD')) || null,
  };
};

// formatting the filter for sending notification to the user
export const formatUserNotificationFilter = user => ({
  filters: {
    in: {
      _id: [user],
    },
  },
});
