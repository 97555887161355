import { Row, Typography } from 'antd';
import React from 'react';

import { DownloadAppDiv, Image } from './Styles';

const { Text } = Typography;

export const DownloadApp = ({ Modal, title }) => (
  <Modal
    keyboard={false}
    maskClosable={false}
    title={title}
    footer={null}
    width={500}
  >
    <>
      <DownloadAppDiv>
        <Row gutter={[20, 20]}>
          <a
            href="https://play.google.com/store/apps/details?id=br.com.usemobile.mps"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/playStore.png" alt="PlayStore" className="logo" />
          </a>
        </Row>
        <Row gutter={[20, 20]}>
          <Text style={{ fontWeight: 'bold' }}>Ou</Text>
        </Row>
        <Row gutter={[20, 20]}>
          <a
            href="https://apps.apple.com/br/app/magra-para-sempre/id1632968785"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Image src="/appleStore.png" alt="AppleStore" className="logo" />
          </a>
        </Row>
      </DownloadAppDiv>
    </>
  </Modal>
);

export default DownloadApp;
