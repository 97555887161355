/* eslint-disable indent */
import { rgba } from 'polished';
import styled from 'styled-components';

const setColor = (color, defaultColor, theme, solid) =>
  theme
    ? {
        background: rgba(theme[color] || defaultColor, solid ? 1 : 0.1),
        border: rgba(theme[color] || defaultColor, solid ? 1 : 0.3),
        color: solid ? 'white' : theme[color] || defaultColor,
      }
    : {
        background: rgba(color || defaultColor, solid ? 1 : 0.1),
        border: rgba(color || defaultColor, solid ? 1 : 0.3),
        color: solid ? 'white' : color || defaultColor,
      };

const colorTheme = (type, theme, color, solid) => {
  switch (type) {
    case 'success':
      return setColor('success', '#67C23A', theme, solid);
    case 'warning':
      return setColor('warning', '#E6A23C', theme, solid);
    case 'danger':
      return setColor('danger', '#F56C6C', theme, solid);
    case 'primary':
      return setColor('primaryColor', '#0000ff', theme, solid);
    case 'secondary':
      return setColor('secondaryColor', '#0000ff', theme, solid);
    case 'color':
      return setColor(color, '#0000ff', null);
    default:
      return setColor('primaryColor', '#0000ff', theme, solid);
  }
};

const setSize = size => {
  switch (size) {
    case 'big':
      return {
        heigth: '32px',
        lineHeigth: '30px',
        padding: '0 10px',
      };
    case 'medium':
      return {
        heigth: '28px',
        lineHeigth: '26px',
        padding: '0 10px',
      };
    case 'small':
      return {
        heigth: '24px',
        lineHeigth: '22px',
        padding: '0 8px',
      };
    case 'mini':
      return {
        heigth: '20px',
        lineHeigth: '17px',
        padding: '0 5px',
      };
    default:
      return {
        heigth: '28px',
        lineHeigth: '26px',
        padding: '0 10px',
      };
  }
};

export const TagWrapper = styled.span`
  background-color: ${props =>
    colorTheme(props.type, props.theme, props.color, props.solid).background};
  display: inline-block;
  padding: ${props => setSize(props.size).padding};
  height: ${props => setSize(props.size).heigth};
  line-height: ${props => setSize(props.size).lineHeigth};
  font-size: 12px;
  color: ${props =>
    colorTheme(props.type, props.theme, props.color, props.solid).color};
  border-radius: ${props => (props.round ? '15px' : '2px')};
  box-sizing: border-box;
  border: 1px solid
    ${props =>
      props.hit
        ? colorTheme(props.type, props.theme, props.color, props.solid).border
        : 'transparent'};
  white-space: nowrap;

  & [class*='icon'] {
    & + span {
      margin-left: 5px;
    }
  }

  & span {
    & + [class*='append-icon'] {
      margin-left: 5px;
    }
  }

  i {
    vertical-align: 1 !important;
  }
`;
