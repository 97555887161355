export const LANGUAGE_CONFIG_PT_BR = {
  moment: { locale: 'pt' },
  numbro: {
    languageTag: 'pt-BR',
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'mil',
      million: 'milhões',
      billion: 'bilhões',
      trillion: 'trilhões',
    },
    ordinal: number => `${number} º`,
    currency: {
      symbol: 'R$',
      position: 'prefix',
      code: 'BRL',
    },
    currencyFormat: {
      thousandSeparated: true,
      // totalLength: 4,
      spaceSeparated: true,
      mantissa: 2,
      optionalMantissa: false,
      average: false,
    },
    formats: {
      fourDigits: {
        totalLength: 4,
        spaceSeparated: true,
        average: false,
      },
      fullWithTwoDecimals: {
        output: 'currency',
        mantissa: 2,
        spaceSeparated: true,
        thousandSeparated: true,
      },
      fullWithTwoDecimalsNoCurrency: {
        mantissa: 2,
        thousandSeparated: true,
      },
      fullWithNoDecimals: {
        output: 'currency',
        spaceSeparated: true,
        thousandSeparated: true,
        mantissa: 0,
      },
    },
  },
};
