import React from 'react';
import { Helmet } from 'react-helmet';

import { Error404 } from 'components';
import { useTheme } from 'hooks';

const NotFound = () => {
  const { primaryColor } = useTheme();
  return (
    <>
      <Helmet title="Página não encontrada" />
      <Error404 theme={primaryColor} />
    </>
  );
};

export default NotFound;
