import { Typography, Col, Skeleton } from 'antd';
import React, { memo } from 'react';

import { InfoProfile, StyleInfo, StyleRow } from './Styles';

const { Text } = Typography;

export const ProfileInformation = ({ user, loading }) => (
  <>
    {(loading && <Skeleton active />) || (
      <StyleRow gutter={[10, 10]}>
        <Col md={24}>
          <StyleInfo>
            <>
              <Col md={15}>
                <InfoProfile>
                  <Text strong>{user?.followingCount}</Text>
                  <Text>Seguindo</Text>
                </InfoProfile>
              </Col>
              <Col md={15}>
                <InfoProfile>
                  <Text strong>{user?.followerCount}</Text>
                  <Text>Seguidores</Text>
                </InfoProfile>
              </Col>
            </>
          </StyleInfo>
        </Col>
      </StyleRow>
    )}
  </>
);

export default memo(ProfileInformation);
