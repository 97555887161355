import styled from 'styled-components';

import theme from 'themes';

const Footer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
  padding: 30px 0;
  color: ${theme.text || '#444'};
  font-size: 14px;
  border-top: 1px solid ${theme.border || '#ddd'};
`;

export default Footer;
