/* eslint-disable no-nested-ternary */
import {
  LoadingOutlined,
  PlusOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Upload as AntUpload, message, Button } from 'antd';
import { Field } from 'formik';
import React, { memo, useState, useEffect } from 'react';

import {
  uploadImage,
  uploadAudio,
  uploadExercises,
  uploadMaps,
  uploadCard,
} from 'services/index';

const Upload = ({
  name,
  onUpload,
  validate,
  imagesUrl,
  resetImage,
  accept,
  defaultFileList,
  ...props
}) => {
  const [fileList, setFileList] = useState(imagesUrl || []);
  const [setLimitReached] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [newImage, setNewImage] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const changeFieldValue = (setFieldValue, setFieldTouched) => {
    if ((isChanged && newImage !== undefined) || imagesUrl?.length > 0) {
      setTimeout(() => {
        setFieldValue(name, fileList);
        setFieldTouched(name, true);
        setIsChanged(false);
      }, 1000);
    }
  };

  useEffect(() => {
    if (resetImage) setFileList([]);
  }, [resetImage]);

  useEffect(() => {
    if (fileList.length === 8) setLimitReached(true);
    setIsChanged(true);
  }, [fileList]);

  const updateFileList = () => {
    if (newImage !== undefined) {
      if (fileList.length < 9) {
        setFileList([
          ...fileList,
          {
            ...newImage,
            thumbUrl: newImage.url,
            uid: newImage.key || newImage.id,
          },
        ]);
      }
    }

    return fileList;
  };

  useEffect(() => {
    updateFileList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newImage]);

  const validateImage = file => false;

  const handleChange = (fieldName, { file }, setFieldValue) =>
    new Promise((resolve, reject) => {
      setIsUploading(true);
      const reader = new FileReader();
      reader.addEventListener('load', async () => {
        if (file.type === 'image/png' && fieldName === 'cover') {
          setNewImage(
            await uploadImage({
              file,
            }),
          );
          setFieldValue(fieldName, fileList);
          setIsUploading(false);
          resolve(fileList);
        } else if (file.type === 'audio/mpeg') {
          setNewImage(
            await uploadAudio({
              file,
            }),
          );
          setFieldValue(fieldName, fileList);
          setIsUploading(false);
          resolve(fileList);
        } else if (
          file.type === 'application/pdf' &&
          fieldName === 'exercises'
        ) {
          setNewImage(
            await uploadExercises({
              file,
            }),
          );
          message.loading('Carregando caderno de exercícios');
          setFieldValue(fieldName, fileList);
          setIsUploading(false);
          resolve(fileList);
        } else if (
          file.type === 'application/pdf' &&
          fieldName === 'illustratedMaps'
        ) {
          setNewImage(
            await uploadMaps({
              file,
            }),
          );
          message.loading('Carregando mapa ilustrado');
          setFieldValue(fieldName, fileList);
          setIsUploading(false);
          resolve(fileList);
        } else if (file.type === 'image/png' && fieldName === 'cards') {
          setNewImage(
            await uploadCard({
              file,
            }),
          );
          message.loading('Carregando card');
          setFieldValue(fieldName, fileList);
          setIsUploading(false);
          resolve(fileList);
        } else if (file.type === 'image/png') {
          setNewImage(
            await uploadImage({
              file,
            }),
          );
          message.loading('Carregando arquivo');
          setFieldValue(fieldName, fileList);
          setIsUploading(false);
          resolve(fileList);
        } else {
          message.error('Apenas upload de arquivos PNG!');
          setIsUploading(false);
          reject();
        }
        message.destroy();
        message.success('Arquivo carregado');
      });
      reader.readAsDataURL(file);
    });

  return (
    <Field name={name} validate={validate}>
      {({ form: { setFieldValue, setFieldTouched } }) => (
        <>
          <AntUpload
            name={name}
            accept={accept}
            listType="text"
            showUploadList={{ removeIcon: true }}
            // defaultFileList={fileList ? defaultFileList : null}
            beforeUpload={validateImage}
            onChange={event => {
              if (!event.file.status || !event.file.status === 'removed') {
                if (!event.file?.status) {
                  if (event.fileList.length < 9) {
                    handleChange(name, event, setFieldValue).then(res => {
                      if (res) {
                        setTimeout(() => {
                          setFieldTouched(name, true);
                        }, 1000);
                      }
                    });
                  } else {
                    setLimitReached(true);
                  }
                } else {
                  setFileList(
                    fileList.filter(file => file.key !== event.file.key),
                  );
                }
              } else {
                setFileList(
                  fileList.filter(file => file.uid !== event.file.uid),
                );
              }
            }}
            {...props}
          >
            <Button
              style={{
                backgroundColor: '#E36926',
                color: '#fff',
                borderRadius: '8px',
                width: '150px',
              }}
            >
              Inserir
            </Button>
            {/* {limitReached && <p>Máximo de 8 imagens</p>}
            {fileList.length >= 8
              ? null
              : accept === 'image/png'
              ? uploadButton
              : uploadCommunButton} */}
            {isChanged && changeFieldValue(setFieldValue, setFieldTouched)}
          </AntUpload>
        </>
      )}
    </Field>
  );
};

export default memo(Upload);
