import { routerMiddleware } from 'connected-react-router';
import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

import createSagaMiddleware from 'redux-saga';
import thunkMiddleware from 'redux-thunk';

import createReducer from 'configs/reducers';
import { Environment } from 'utils/constants';

export default function configureStore(initialState = {}, history) {
  let composeEnhancers = composeWithDevTools({ trace: true, traceLimit: 25 });
  const reduxSagaMonitorOptions = {};

  // If Redux Dev Tools and Saga Dev Tools Extensions are installed, enable them
  /* istanbul ignore next */
  if (
    process.env.NODE_ENV !== Environment.PRODUCTION &&
    typeof window === 'object'
  ) {
    /* eslint-disable no-underscore-dangle */
    if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
      composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({});

    // NOTE: Uncomment the code below to restore support for Redux Saga
    // Dev Tools once it supports redux-saga version 1.x.x
    // if (window.__SAGA_MONITOR_EXTENSION__)
    //   reduxSagaMonitorOptions = {
    //     sagaMonitor: window.__SAGA_MONITOR_EXTENSION__,
    //   };
    /* eslint-enable */
  }

  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  // mexi aqui
  // const loggerMiddleware = createLogger();

  // Create the store with middlewares
  // 1. sagaMiddleware: Makes redux-sagas work
  // 2. routerMiddleware: Syncs the location/URL path to the state
  // 3. thunkMiddleware: Mekes actions Async
  // 4. loggerMiddleware: Creates logs to redux operations
  const middlewares = [
    sagaMiddleware,
    routerMiddleware(history),
    thunkMiddleware,
  ];
  if (process.env.NODE_ENV === 'development') middlewares.push(logger);
  // mexi aqui
  // if (
  //   process.env.REACT_APP_REDUX_LOG === undefined ||
  //   process.env.REACT_APP_REDUX_LOG === true
  // ) {
  //   middlewares.push(loggerMiddleware);
  // }

  const enhancers = [applyMiddleware(...middlewares)];

  const store = createStore(
    createReducer(),
    initialState,
    composeEnhancers(...enhancers),
  );

  // Extensions
  store.runSaga = sagaMiddleware.run;
  store.injectedReducers = {}; // Reducer registry
  store.injectedSagas = {}; // Saga registry

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      store.replaceReducer(createReducer(store.injectedReducers));
    });
  }

  return store;
}
