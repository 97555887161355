import * as Yup from 'yup';

import 'validations';
import { MIN_MESSAGE, REQUIRED_MESSAGE } from 'validations/messages';

export const CHANGE_PASSWORD_VALIDATION = Yup.object().shape({
  password: Yup.string()
    .required(REQUIRED_MESSAGE('senha'))
    .min(6, MIN_MESSAGE('senha', 6)),
  passwordConfirm: Yup.string()
    .required(REQUIRED_MESSAGE('confirme a senha'))
    .equalTo(Yup.ref('password'), 'As senhas não conferem'),
});
