import styled from 'styled-components';

const AccessPageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 100px;

  p {
    font-size: 14px;
    color: #909399;
    line-height: 22px;
    margin-bottom: 9px;
  }
`;

export default AccessPageContainer;
