import { Layout } from 'antd';
import styled from 'styled-components';

export const Header = styled(Layout.Header)`
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 65px;
  background-color: #222;
`;

export const Content = styled(Layout.Content)`
  margin-top: 65px;
  overflow: initial;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
