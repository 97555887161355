import styled from 'styled-components';

const AccessPageCard = styled.div`
  margin-bottom: 50px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default AccessPageCard;
