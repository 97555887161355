import { Tooltip } from 'antd';
import React, { memo } from 'react';

import { Tag } from 'components';
import { useTheme } from 'hooks';

import { OnOffWrapper } from './Styles';

const OnOff = ({
  isOn,
  size,
  onText = 'Online',
  offText = 'Offline',
  expanded,
  style,
  icon,
  iconOff,
  iconOn,
  sizeTag = 'mini',
  onClick,
}) => {
  const { success, danger } = useTheme();

  const handleClick = () => {
    if (onClick) onClick();
  };

  const generateIcon = () =>
    icon ||
    (iconOn && iconOff && (isOn ? iconOn : iconOff)) || (
      <OnOffWrapper
        isOn={isOn}
        size={size || '10px'}
        success={success}
        danger={danger}
        style={{ marginRight: '5px' }}
      />
    );

  return (
    <div
      onClick={handleClick}
      style={{ cursor: onClick ? 'pointer' : 'default' }}
    >
      {(!expanded && (
        <Tooltip title={isOn ? onText : offText}>
          <OnOffWrapper
            isOn={isOn}
            size={size}
            success={success}
            danger={danger}
            style={{ ...style }}
          />
        </Tooltip>
      )) || (
        <Tag
          type={isOn ? 'success' : 'danger'}
          size={sizeTag}
          hit
          style={{ ...style }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {icon !== 'off' && generateIcon()}
            {isOn ? onText : offText}
          </div>
        </Tag>
      )}
    </div>
  );
};

export default memo(OnOff);
