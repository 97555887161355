import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  html,
  body {
    width: 100%;
    height: 100%;
    color: #303133;
  }

  * {
  font-family: 'Asap', sans-serif !important;
  }

 

  body.fontLoaded {
    font-family: 'Asap', sans-serif !important;
  }

  #app {
    min-width: 100%;
    min-height: 100%;
    background-color: #f1f2f5;

    .ant-layout-header {
      padding: 0 0 0 5px;
    }
  }

  .more-detail > .ant-collapse-item >
  .ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
  }
  .more-detail > .ant-collapse-item > .ant-collapse-header  {
    padding: 0 !important;
    padding-left: 20px !important;
  }

  .more-detail > .ant-collapse-item {
    margin-left: -18px;
  }

  .more-detail.ant-collapse {
    border: none !important;
  }

  .more-detail > .ant-collapse-item .ant-collapse-header {
    font-weight: 600 !important;
  }

  .ant-alert-close-icon {
    top: 13px !important;
  }

  p,
  label {
    font-family: Georgia, Times, 'Times New Roman', serif;
    line-height: 1.5em;
  }

  @media (max-width: 1101px) {

   .data-header {
    min-width: 100% !important;

    .avatar, .rate {
      display: flex;
      justify-content: center;
    }
   }
  }

  /* width */
::-webkit-scrollbar {
  width: 2px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #C0C2C4;
  border-radius: 500px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}
/* Slide Fade Up */
.slide-fadein-up-enter {
  opacity: 0;
  transform: translateY(20px);
}
.slide-fadein-up-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.3s, transform 0.4s;
}
.slide-fadein-up-exit {
  opacity: 1;
  transform: translateY(0);
}
.slide-fadein-up-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.3s, transform 0.4s;
}
/* Slide Fade Right */
.slide-fadein-right-enter {
  opacity: 0;
  transform: translateX(-20px);
}
.slide-fadein-right-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.3s, transform 0.4s;
}
.slide-fadein-right-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-fadein-right-exit-active {
  opacity: 0;
  transform: translateX(20px);
  transition: opacity 0.3s, transform 0.4s;
}
/* Zoom Fade */
.zoom-fadein-enter {
  opacity: 0;
  transform: scale(0.99);
}
.zoom-fadein-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 0.3s, transform 0.3s;
}
.zoom-fadein-exit {
  opacity: 1;
  transform: scale(1);
}
.zoom-fadein-exit-active {
  opacity: 0;
  transform: scale(1.01);
  transition: opacity 0.3s, transform 0.3s;
}
/* Fade */
.fadein-enter {
  opacity: 0;
}
.fadein-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}
.fadein-exit {
  opacity: 1;
}
.fadein-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

`;

export default GlobalStyle;
