import { Typography } from 'antd';
import styled from 'styled-components';

import theme from 'themes';

const { Title, Text } = Typography;

export const Error = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10000000;
  background-color: ${theme.background || 'white'} !important;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    font-family: 'Source Sans Pro', sans-serif;
    background-color: ${theme.background || 'white'};
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
`;

export const Typo = styled(Typography)`
  max-width: 500px;
  height: 550px;
`;

export const ErrorTitle = styled(Title)`
  margin-top: 20px !important;
  font-weight: ${props => `${props.fontWeight}` || '400'}!important;
  font-size: ${props => `${props.fontSize}` || '1rem'}!important;
`;

export const ErrorText = styled(Text)`
  font-weight: ${props => `${props.fontWeight}` || '400'}!important;
  font-size: ${props => `${props.fontSize}` || '1rem'}!important;
`;
