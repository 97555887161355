import styled from 'styled-components';

export const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  float: right;
  min-height: 65px;
  padding: 15px 20px;
  background-color: transparent;
`;
