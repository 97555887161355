export const REQUIRED_MESSAGE = field => `O campo '${field}' é obrigatório`;
export const MIN_MESSAGE = (field, value) =>
  `O campo '${field}' deve ter ao menos ${value} caracteres`;
export const MAX_MESSAGE = (field, value) =>
  `O campo '${field}' deve ter no máximo ${value} caracteres`;
export const EMAIL_INVALID_MESSAGE = 'O campo de "e-mail" é inválido';
export const CPF_INVALID_MESSAGE = 'O campo de "CPF" é inválido';
export const CPF_INCOMPLETE_MESSAGE = 'O campo de "CPF" está incompleto';

export const NUMBER_MESSAGE = field => `O campo '${field}' deve ser numérico`;

export const REQUIRED_TAG_THEME = field =>
  `É necessário adicionar ao menos um(a) '${field}'`;
