import { isNil, isEmpty, isNumber } from 'lodash';
import React from 'react';

import { TagTextItalic } from 'components';

export const formatOutput = value =>
  isNil(value) || (isEmpty(value) && !isNumber(value)) ? (
    <TagTextItalic>Não informado</TagTextItalic>
  ) : (
    value
  );
export const isOutputPresent = value => !isNil(value);
