import { Row } from 'antd';
import styled from 'styled-components';

export const InfoProfile = styled.div`
  padding-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyleInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const StyleRow = styled(Row)`
  flex-direction: column;
  align-items: center;
`;
