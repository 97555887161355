import { message } from 'antd';
import { call, put, takeLatest, select } from 'redux-saga/effects';

import { getUserActivities } from 'services/activities';
import { getTags } from 'services/categories';
import {
  getUser,
  blockUnblockUser,
  activateDeactivateUser,
  makeOrRevokeAdministrator,
  changePasswordUser,
  deleteUser,
  getUsers,
  getAdressByZipCode,
  getStates,
  getCitiesByState,
  resetUserInfo,
} from 'services/index';
import { sendNotification } from 'services/notifications';
import { MAX_LIMIT } from 'utils/constants';
import { formatActivitiesResponse } from 'utils/formats/activity/index';
import {
  formatBlockingMessage,
  formatSearchCitiesByStateFilter,
} from 'utils/formats/index';
import { formatUserNotificationFilter } from 'utils/formats/notifications/formatFilters';
import { formatUserFilter } from 'utils/formats/products/formatFilters';
import history from 'utils/history';

import { fetchClients } from '../Clients/actions';
import {
  fetchUser,
  fetchUserSuccess,
  fetchAddressByZipCodeSuccess,
  fetchStatesSuccess,
  fetchCitiesByStateSuccess,
  fetchCategoriesTypesListSuccess,
} from './actions';
import {
  FETCH_USER,
  BLOCK_UNBLOCK_USER,
  ACTIVATE_DEACTIVATE_USER,
  MAKE_OR_REVOKE_ADMINISTRATOR,
  CHANGE_PASSWORD_USER,
  DELETE_USER,
  SEND_NOTIFICATION,
  FETCH_USER_ACTIVITIES,
  FETCH_USER_BY_EMAIL,
  FETCH_ADDRESS_BY_ZIP_CODE,
  FETCH_STATES,
  FETCH_CITIES_BY_STATE,
  FETCH_CATEGORIES_TYPES,
  RESET_USER,
} from './constants';
import {
  selectUserActivitiesTableConfigs,
  selectProductsFilters,
  selectProductPaginationConfigs,
} from './selectors';

export function* loadUser({ userId }) {
  const filters = formatUserFilter(yield select(selectProductsFilters()));
  const paginationConfigs = yield select(selectProductPaginationConfigs());
  try {
    const response = yield call(getUser, {
      userId,
    });
    yield put(fetchUserSuccess(response));
  } catch (err) {
    console.error(err.message);
  }
}

export function* resetUser({ payload }) {
  try {
    yield call(resetUserInfo, payload);
    message.success('Usuário resetado com sucesso.');
  } catch (error) {
    message.error('Erro ao apagar os dados. Tente novamente mais tarde.');
    console.error(error.message);
  }
}

export function* blockUser({ userId, isBlocking, profile }) {
  try {
    yield call(blockUnblockUser, { userId, isBlocking });
    message.success(formatBlockingMessage(profile, isBlocking));
    switch (profile) {
      case 'client':
        yield put(fetchUser(userId, 'client'));
        break;
      case 'clients':
        yield put(fetchClients());
        break;
      default:
        break;
    }
  } catch (err) {
    console.error(err.message);
  }
}

export function* activateUser({ userId, isActive, profile }) {
  try {
    yield call(activateDeactivateUser, {
      userId,
      isActive,
    });
    message.success(
      `${profile === 'client' ? 'Cliente' : 'Profissional'} ${
        isActive ? 'desativado' : 'ativado'
      } com sucesso!`,
    );
    yield put(
      fetchUser(userId, profile === 'client' ? 'client' : 'professional'),
    );
  } catch (err) {
    console.error(err.message);
  }
}

export function* makeRevokeAdministrator({
  userId,
  password,
  isAdministrator,
  profile,
}) {
  try {
    yield call(makeOrRevokeAdministrator, {
      userId,
      data: { password },
      isAdministrator,
    });
    message.success(
      `Permissão ${isAdministrator ? 'removida' : 'concedia'} com sucesso!`,
    );
    switch (profile) {
      case 'client':
        yield put(fetchUser(userId, profile));
        break;
      case 'professional':
        yield put(fetchUser(userId, profile));
        break;
      case 'administrator':
        break;
      default:
        break;
    }
  } catch (err) {
    console.error(err.message);
  }
}

export function* changePassword({ userId, newPassword }) {
  try {
    yield call(changePasswordUser, { userId, data: { newPassword } });
    message.success('Senha alterada com sucesso !');
  } catch (err) {
    message.error('Não foi possível trocar a senha, tente novamente.');
  }
}

export function* notifyUser({ users, formOptions }) {
  const objectFilters = formatUserNotificationFilter(users, formOptions);
  const data = {
    filters: objectFilters.filters,
    message: formOptions?.message,
  };
  try {
    yield call(sendNotification, data);
    message.success('Notificação enviada com sucesso!');
  } catch (err) {
    console.error(err.message);
  }
}

export function* removeUser({ userId }) {
  try {
    yield call(deleteUser, { userId });
    message.success('Usuário removido com sucesso!');
    history.push('/users');
  } catch (err) {
    console.error(err.message);
  }
}

export function* loadAddressByZipCode({ zipCode }) {
  try {
    const addressByZipCode = yield call(getAdressByZipCode, zipCode);
    yield put(fetchAddressByZipCodeSuccess(addressByZipCode));
  } catch (err) {
    console.error(err.message);
  }
}

export function* loadStates() {
  try {
    const { docs } = yield call(getStates);
    yield put(fetchStatesSuccess(docs));
  } catch (err) {
    console.error(err.message);
  }
}

export function* loadByStatesCities({ stateId }) {
  const filter = formatSearchCitiesByStateFilter(stateId);
  try {
    const { docs } = yield call(getCitiesByState, filter);
    yield put(fetchCitiesByStateSuccess(docs));
  } catch (err) {
    console.error(err.message);
  }
}

export function* loadCategoriesTypes() {
  try {
    const { categories } = yield call(getTags, {
      limit: MAX_LIMIT,
      sortBy: 'name',
    });
    yield put(fetchCategoriesTypesListSuccess(categories.docs));
  } catch (err) {
    console.error(err.message);
  }
}

export default function* appLayoutSaga() {
  yield takeLatest(FETCH_USER, loadUser);
  yield takeLatest(BLOCK_UNBLOCK_USER, blockUser);
  yield takeLatest(ACTIVATE_DEACTIVATE_USER, activateUser);
  yield takeLatest(MAKE_OR_REVOKE_ADMINISTRATOR, makeRevokeAdministrator);
  yield takeLatest(CHANGE_PASSWORD_USER, changePassword);
  yield takeLatest(SEND_NOTIFICATION, notifyUser);
  yield takeLatest(DELETE_USER, removeUser);
  yield takeLatest(FETCH_ADDRESS_BY_ZIP_CODE, loadAddressByZipCode);
  yield takeLatest(FETCH_STATES, loadStates);
  yield takeLatest(FETCH_CITIES_BY_STATE, loadByStatesCities);
  yield takeLatest(FETCH_CATEGORIES_TYPES, loadCategoriesTypes);
  yield takeLatest(RESET_USER, resetUser);
}
