import { UserOutlined } from '@ant-design/icons';
import { Avatar as Avat, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { MultiAvatar } from './Styles';

class AvatarBuilder {
  constructor(avatar) {
    this.builder = avatar;
  }

  withLink(link, index) {
    this.builder = link ? (
      <Link key={index} to={link}>
        {this.builder}
      </Link>
    ) : (
      this.builder
    );
    return this;
  }

  withTooltip(title, index) {
    this.builder = title ? (
      <Tooltip key={index} title={title}>
        {this.builder}
      </Tooltip>
    ) : (
      this.builder
    );
    return this;
  }

  build() {
    return this.builder;
  }
}

const Avatar = ({
  src,
  link,
  name,
  title,
  multiValues,
  icon = <UserOutlined />,
  ...props
}) => {
  const avatar = source => <Avat icon={icon} src={source} {...props} />;

  return (
    <>
      {multiValues ? (
        <MultiAvatar>
          {multiValues.map((value, index) =>
            new AvatarBuilder(avatar(value.src))
              .withTooltip(value.name || title)
              .withLink(value.link, index)
              .build(),
          )}
        </MultiAvatar>
      ) : (
        new AvatarBuilder(avatar(src))
          .withTooltip(name || title)
          .withLink(link)
          .build()
      )}
    </>
  );
};

export default Avatar;
