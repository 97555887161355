import * as Yup from 'yup';

import { REQUIRED_MESSAGE } from 'validations/messages';

export const MENUS_VALIDATION = Yup.object().shape({
  title: Yup.string().required(REQUIRED_MESSAGE('Título do conteúdo')),
  caloriesGoal: Yup.number().required(REQUIRED_MESSAGE('Meta de calorias')),
  fatGoal: Yup.number().required(REQUIRED_MESSAGE('Meta de gordura')),
  carbohydratesGoal: Yup.number().required(
    REQUIRED_MESSAGE('Meta de carboidratos'),
  ),
  proteinGoal: Yup.number().required(REQUIRED_MESSAGE('Meta de proteínas')),
  // cover: Yup.string().required(REQUIRED_MESSAGE('Capa do conteúdo')),
  // pdf: Yup.string().required(REQUIRED_MESSAGE('Pdf')),
});
