import req from 'utils/request';
import { capitalize } from 'lodash';
import { Row, Col, Drawer, Spin, Skeleton, Button } from 'antd';
import { Formik } from 'formik';
import React, { memo, useState, useEffect } from 'react';

import { Form, Input, SubmitButton, UploadMultiple, Select } from 'components';
import { DRAWER_PROPS } from 'utils/layout/components/drawer';
import { CHALLENGES_VALIDATION } from 'validations/challenges';
import { history } from 'index';

import {
  LabelContainer,
  FormTitleContainer,
  UploadContainer,
} from './ActivitiesForm.styles';

const ChallengeCreateOrUpdate = ({
  challenge,
  loading = false,
  isOpen,
  onClose,
  onSave,
  onReset,
}) => {
  const [resetImage, setResetImage] = useState(false);
  const [nonReleases, setNonReleases] = useState([]);
  const [menuId, setValue] = useState(undefined);
  const [defaultMenuId, setDefaultMenuId] = useState(undefined);

  useEffect(() => {
    if (challenge) {
      setDefaultMenuId(challenge.menu.title);
    }
  }, [challenge]);

  const titleFormProduct = () => {
    if (loading) return <Skeleton paragraph={{ rows: 1 }} />;
    return `${challenge?.id ? 'Editar' : 'Novo'} desafio`;
  };

  const chageListImage = () => {
    setResetImage(true);
    onReset();
  };

  async function fetch(query) {
    const response = await req().get(`/menus?page=1&limit=9999&query=${query}`);
    setNonReleases(response.menus);
  }

  const handleSearch = async query => {
    if (query) {
      await fetch(query);
    } else {
      setNonReleases([]);
    }
  };

  const handleChange = menuId => {
    setValue(menuId);
  };

  const options = nonReleases.map(d => (
    <Select.Option key={d.id}>
      <span>{capitalize(d.title.toLowerCase())}</span>
    </Select.Option>
  ));

  return (
    <>
      <Drawer
        style={{ zIndex: '1024' }}
        title={titleFormProduct()}
        visible={isOpen}
        onClose={onClose}
        {...DRAWER_PROPS}
      >
        {!loading ? (
          <Formik
            enableReinitialize
            initialValues={{
              ...challenge,
            }}
            validateOnMount
            validationSchema={CHALLENGES_VALIDATION}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const newValues = {
                ...values,
                menuId: menuId || challenge.menu.id,
              };
              setTimeout(() => {
                if (onSave) onSave({ ...newValues });
                setSubmitting(false);
                resetForm();
              }, 800);
            }}
          >
            <Form layout="vertical">
              <FormTitleContainer>Informações básicas</FormTitleContainer>
              <Row>
                <Col md={24}>
                  <Form.Item
                    className="form__item"
                    required
                    name="name"
                    label="Título do desafio"
                  >
                    <Input
                      name="name"
                      placeholder="Insira o título do desafio"
                    />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    className="form__item"
                    required
                    name="menuId"
                    label="Escolha o cardápio do desafio"
                  >
                    <Select
                      name="menuId"
                      className="ant-select-selector search-select"
                      showSearch
                      value={menuId}
                      defaultValue={menuId || defaultMenuId}
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={handleSearch}
                      onChange={handleChange}
                      notFoundContent={null}
                      placeholder="Escolher cardápio"
                    >
                      {options}
                    </Select>
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    className="form__item"
                    required
                    name="vegetableGoal"
                    label="Meta de verduras"
                  >
                    <Input
                      type="number"
                      min="0"
                      name="vegetableGoal"
                      placeholder="Insira a quantidade de verduras"
                    />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    className="form__item"
                    required
                    name="fruitGoal"
                    label="Meta de frutas"
                  >
                    <Input
                      type="number"
                      min="0"
                      name="fruitGoal"
                      placeholder="Insira a quantidade de frutas"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <FormTitleContainer>Conteúdo</FormTitleContainer>
              <Row>
                <Col md={24}>
                  <Form.Item
                    className="form__item"
                    required
                    name="content"
                    label="Informações do desafio"
                  >
                    <Input.TextArea
                      name="content"
                      placeholder="Insira todas as informações do desafio"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 0]} style={{ marginTop: '30px' }} justify="end">
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Button
                      style={{
                        border: '1px solid #E36926',
                        borderRadius: '8px',
                        marginRight: '15px',
                        color: '#E36926',
                      }}
                      onClick={() => {
                        onClose && onClose();
                        history.go(0);
                      }}
                    >
                      Cancelar
                    </Button>
                    <SubmitButton showValidationErrors>Adicionar</SubmitButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Formik>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default memo(ChallengeCreateOrUpdate);
