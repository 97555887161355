import { Tooltip } from 'antd';
import React, { memo } from 'react';

import { CircleWrapper } from './Styles';

const CircleStatus = ({ text, color }) => (
  <>
    <Tooltip title={text}>
      <CircleWrapper color={color} />
    </Tooltip>
  </>
);

export default memo(CircleStatus);
