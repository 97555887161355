import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

const TagTextItalic = styled(Text)`
  color: rgb(159, 157, 164) !important;
  font-style: italic !important;
  font-weight: 500 !important;
`;

export default TagTextItalic;
