/* eslint-disable indent */
import { createGlobalStyle } from 'styled-components';

const NLoader = createGlobalStyle`
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1031;
    width: 100%;
    height: 3px;
    background: '#6D6E70';
  }

  /* Fancy blur effect */
  #nprogress .peg {
    position: absolute;
    right: 0;
    display: block;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px '#6D6E70',
      0 0 5px '#6D6E70';
    -webkit-transform: rotate(3deg) translate(0, -4px);
    -ms-transform: rotate(3deg) translate(0, -4px);
    transform: rotate(3deg) translate(0, -4px);
    opacity: 1;

  }

  #nprogress .spinner {
    position: fixed;
    top: 13px;
    right: auto;
    left: 50%;
    z-index: 1031;
    display: block;
    width: 140px;
    margin-left: -73px;
    padding: 8px 15px;
    background: ${props => (props.theme && props.theme.background) || 'white'};
    border: 1px solid '#d8d8d8';
    border-radius: 4px;
    &::after {
      position: absolute;
      top: 7px;
      left: 48px;
      display: inline-block;
      content: 'Carregando...';
    }
  }

  #nprogress .spinner-icon {
    box-sizing: border-box;
    width: 18px;
    height: 18px;
    border: solid 2px transparent;
    border-top-color: #6D6E70;
    border-left-color: #6D6E70;
    border-radius: 50%;
    -webkit-animation: nprogress-spinner 400ms linear infinite;
    animation: nprogress-spinner 400ms linear infinite;
  }

  .nprogress-custom-parent {
    position: relative;
    overflow: hidden;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @-webkit-keyframes nprogress-spinner {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default NLoader;
