/* eslint-disable indent */
import styled from 'styled-components';

export const OnOffWrapper = styled.div`
  height: ${props => props.size || '15px'};
  width: ${props => props.size || '15px'};

  background-color: ${props =>
    props.isOn ? props.success || 'green' : props.danger || 'red'};

  border-radius: 200px;
`;
