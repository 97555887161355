/* eslint-disable indent */
import {
  UserOutlined,
  ToolOutlined,
  HomeOutlined,
  InboxOutlined,
  BellOutlined,
  DashboardOutlined,
  SettingOutlined,
  NotificationOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import Loadable from 'react-loadable';

import { ACCESS_LEVEL } from 'utils/authentication';

const loadable = loader =>
  Loadable({
    loader,
    delay: false,
    loading: () => (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '75vh',
        }}
      >
        <Spin />
      </div>
    ),
  });

const load = component => loadable(() => import(`containers/${component}`));

export const ROUTES = () => [
  {
    key: 0,
    title: 'Login',
    path: '/system/login',
    Component: load('Login'),
    exact: true,
    hideSidebar: true,
    accessLevel: ACCESS_LEVEL.public(),
  },
  {
    key: 1,
    title: 'Recuperar senha',
    path: '/system/recover-password',
    Component: load('RecoverPassword'),
    exact: true,
    accessLevel: ACCESS_LEVEL.public(),
    parent: [
      {
        key: 0,
        title: 'Login',
        path: '/system/login',
      },
    ],
  },
  {
    key: 2,
    title: 'Alterar senha',
    path: '/system/change-login-password/:token',
    Component: load('ChangeLoginPassword'),
    exact: true,
    accessLevel: ACCESS_LEVEL.public(),
    parent: [
      {
        key: 0,
        title: 'Login',
        path: '/system/login',
      },
    ],
  },
  {
    key: 300,
    title: 'Alterar senha',
    path: '/system/admin/change-login-password/:token',
    Component: load('ChangeLoginPassword'),
    exact: true,
    accessLevel: ACCESS_LEVEL.public(),
    parent: [
      {
        key: 0,
        title: 'Login',
        path: '/system/login',
      },
    ],
  },
  {
    key: 301,
    title: 'Senha alterada',
    path: '/system/password-modified',
    Component: load('PasswordChanged'),
    exact: true,
    accessLevel: ACCESS_LEVEL.public(),
    parent: [
      {
        key: 0,
        title: 'Login',
        path: '/system/login',
      },
    ],
  },
  {
    key: 3,
    title: 'Visão Geral',
    icon: <HomeOutlined />,
    path: '/dashboard',
    Component: load('Dashboard'),
    exact: true,
    accessLevel: ACCESS_LEVEL.public(),
  },
  {
    key: 4,
    title: 'Método MPS',
    icon: <DashboardOutlined />,
    path: '/metodo-mps',
    Component: load('TabsContainer'),
    exact: true,
    accessLevel: ACCESS_LEVEL.ADMINISTRATOR,
  },
  {
    key: 27,
    title: 'Módulos',
    icon: <PlaySquareOutlined />,
    path: '/modules',
    Component: load('Modules'),
    exact: true,
    accessLevel: ACCESS_LEVEL.ADMINISTRATOR,
  },
  {
    key: 5,
    title: 'Detalhes do usuário',
    icon: '',
    path: '/client/:id',
    Component: load('Client'),
    exact: true,
    parent: [
      {
        key: 4,
        title: 'Usuários',
        path: '/users',
      },
    ],
  },
  {
    key: 12,
    icon: <UserOutlined />,
    title: 'Usuários',
    path: '/users',
    Component: load('Users'),
    exact: true,
    accessLevel: [ACCESS_LEVEL.ADMINISTRATOR],
  },
  {
    key: 15,
    title: 'Notificações',
    icon: <BellOutlined />,
    path: '/notifications',
    Component: load('Notifications'),
    exact: true,
    accessLevel: ACCESS_LEVEL.public(),
  },
  {
    key: 241,
    icon: <NotificationOutlined />,
    title: 'Lives',
    path: '/live',
    Component: load('Live'),
    exact: true,
    accessLevel: [ACCESS_LEVEL.ADMINISTRATOR],
  },
  {
    key: 242,
    icon: <InboxOutlined />,
    title: 'Suporte ao aplicativo',
    path: '/faq',
    Component: load('Help'),
    exact: true,
    accessLevel: [ACCESS_LEVEL.ADMINISTRATOR],
  },
  {
    key: 24,
    icon: <SettingOutlined />,
    title: 'Configurações',
    path: '/configurations',
    Component: load('Settings'),
    exact: true,
    accessLevel: [ACCESS_LEVEL.ADMINISTRATOR],
  },
  {
    key: 19,
    title: 'Aulas do módulo',
    icon: <ToolOutlined />,
    path: '/modules/:id',
    Component: load('Module'),
    exact: true,
    accessLevel: ACCESS_LEVEL.public(),
    parent: [
      {
        key: 16,
        title: 'Módulo',
        path: '/modules',
      },
    ],
  },
  {
    key: 57,
    title: 'Detalhes do vídeo',
    icon: <ToolOutlined />,
    path: '/modules/classes/:id',
    Component: load('Classes'),
    exact: true,
    accessLevel: ACCESS_LEVEL.public(),
    parent: [
      {
        key: 157,
        title: 'Módulo',
        path: '/modules',
      },
    ],
  },
  {
    key: 21,
    title: 'Publicação compartilhada',
    icon: '',
    path: '/system/share',
    Component: load('PublicationSharedPage'),
    exact: true,
    hideSidebar: true,
    accessLevel: ACCESS_LEVEL.public(),
  },
  {
    key: 23,
    title: 'Validação de e-mail',
    icon: '',
    path: '/system/email-validation',
    Component: load('EmailValidationPage'),
    exact: true,
    hideSidebar: true,
    accessLevel: ACCESS_LEVEL.public(),
  },
];
