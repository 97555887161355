import Moment from 'moment';
import { extendMoment } from 'moment-range';
import NProgress from 'nprogress';
import numbro from 'numbro';
import React, { memo, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch } from 'react-redux';
import { Redirect, useLocation } from 'react-router-dom';

import { NLoader } from 'components';
import { useSettings, useReducerAndSaga, useSession } from 'hooks';
import { getAppLanguage } from 'utils/language';

import { setBaseConfig } from '../../configs/index';
import AuthLayout from './AuthLayout';
import DefaultLayout from './DefaultLayout';
import PublicLayout from './PublicLayout';
import reducer from './reducer';
import saga from './saga';

const Layouts = {
  public: PublicLayout,
  auth: AuthLayout,
  default: DefaultLayout,
};
const moment = extendMoment(Moment);

export const AppLayout = ({ routes, children }) => {
  const dispatch = useDispatch();
  useReducerAndSaga('appLayout', reducer, saga);
  const linguage = useSettings();
  const [prevPath, setPrevPath] = useState('');

  const location = useLocation();
  const currentPath = location ? location.pathname + location.search : '';

  const { isAuthenticated, getAccessData } = useSession();
  const user = getAccessData();

  useEffect(() => {
    if (
      (location?.pathname === '/denounces/publications-removed' &&
        location?.search === '') ||
      (location?.pathname === '/dashboard' && location?.search === '')
    )
      window.scrollTo(0, 0);
    else if (
      location?.pathname !== '/denounces/publications-removed' &&
      location?.pathname !== '/dashboard'
    ) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  useEffect(() => {
    setBaseConfig();
  }, [dispatch]);

  useEffect(() => {
    const languageConfig = getAppLanguage(linguage);
    moment.locale(languageConfig.moment.locale);
    numbro.registerLanguage(languageConfig.numbro);
    numbro.setLanguage(languageConfig.numbro.languageTag);
  }, [linguage]);

  if (prevPath !== currentPath) {
    NProgress.start();
  }

  setTimeout(() => {
    NProgress.done();
    setPrevPath(currentPath);
  }, 300);

  const getLayout = () => {
    if (location && location.pathname === '/') {
      return 'public';
    }

    if (location && /^\/system(?=\/|$)/i.test(location.pathname)) {
      return 'auth';
    }

    return 'default';
  };

  const Container = Layouts[getLayout()];
  const isAuthLayout = getLayout() === 'auth';

  const BootstrappedLayout = () => {
    if (!isAuthLayout && !isAuthenticated()) {
      return <Redirect to="/system/login" />;
    }
    if (isAuthLayout && isAuthenticated()) {
      if (!location.pathname.includes('share')) return <Redirect to="/" />;
    }
    return (
      <Container routes={routes} user={user}>
        {children}
      </Container>
    );
  };

  return (
    <>
      <Helmet titleTemplate={`%s | ${'Dashboard'}`} title="Mps" />
      {BootstrappedLayout()}
      <NLoader />
    </>
  );
};

export default memo(AppLayout);
