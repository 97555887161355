import { createSelector } from 'reselect';

import { initialState } from './reducer';

const notificationsDomain = (state) => state.notifications || initialState;

const selectNotifications = () =>
  createSelector(notificationsDomain, (substate) => substate.notifications);

const selectTotalPage = () =>
  createSelector(notificationsDomain, (substate) => substate.totalPage);

const selectNotificationsFilters = () =>
  createSelector(notificationsDomain, (substate) => substate.filters);

const selectNotificationsTableConfigs = () =>
  createSelector(notificationsDomain, (substate) => substate.tableConfigs);

const selectNotificationsLoader = () =>
  createSelector(
    notificationsDomain,
    (substate) => substate.loadingNotifications,
  );

const selectSearchedUsers = () =>
  createSelector(notificationsDomain, (substate) => substate.searchedUsers);

export default notificationsDomain;
export {
  selectNotifications,
  selectTotalPage,
  selectNotificationsFilters,
  selectNotificationsTableConfigs,
  selectNotificationsLoader,
  selectSearchedUsers,
};
