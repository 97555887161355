import Icon, { InfoCircleOutlined } from '@ant-design/icons';
import { Statistic as AntStatistic, Tooltip, Typography, Row, Col } from 'antd';
import { isEmpty } from 'lodash';
import numbro from 'numbro';
import React, { memo, useState } from 'react';

import { Card, DownloadCsv } from 'components';
import { useTheme } from 'hooks';
import { HEADER_GENERIC } from 'utils/layout/headersCsv/index';

const Statistic = ({
  value,
  icon,
  title,
  loading,
  card = true,
  info,
  format,
  height,
  inverted = false,
  actions,
  iconColor,
  noBorder,
  download = false,
  downloadCsv,
  dataCsv,
  fileName,
  ...props
}) => {
  const { primaryColor } = useTheme();

  const [readyToDownload, setReadyToDownload] = useState(false);

  const withInvertedStatistic = () => (
    <Row>
      {icon && (
        <Col
          style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}
        >
          <Icon
            style={{ fontSize: '32px', color: primaryColor }}
            component={icon}
          />
        </Col>
      )}
      <Col md={18}>
        <AntStatistic
          title={title}
          value={value && format ? numbro(value).format(format) : value}
          {...props}
        />
      </Col>
    </Row>
  );

  const onDownload = () => {
    setReadyToDownload(true);
  };

  const withStatistic = () => (
    <>
      <Row>
        {icon && (
          <Col
            md={2}
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '20px',
            }}
          >
            <Icon
              style={{ fontSize: '32px', color: iconColor || primaryColor }}
              component={icon}
            />
          </Col>
        )}
        <Col md={16}>
          <AntStatistic
            value={value && format ? numbro(value).format(format) : value}
            {...props}
          />
          {title && <Typography.Text type="secondary">{title}</Typography.Text>}
        </Col>
        <Col md={4} />
      </Row>
      {download && (
        <Row style={{ float: 'right' }}>
          <Col>
            <DownloadCsv
              onDownload={() => onDownload()}
              loadingCsv={loading}
              header={HEADER_GENERIC}
              filename={title}
              dataCsv={dataCsv}
              loadingTable={isEmpty(dataCsv)}
              isReady={readyToDownload}
              shape="round"
              ghost
            />
          </Col>
        </Row>
      )}
    </>
  );

  return (
    <>
      {(card && (
        <Card
          loading={loading}
          style={
            height && !loading
              ? { minHeight: height, border: 'none' }
              : undefined
          }
          actions={actions}
          className={noBorder && 'statisticWithoutBorder'}
        >
          {info && (
            <Tooltip title={info}>
              <InfoCircleOutlined
                style={{
                  color: primaryColor,
                  float: 'right',
                  marginTop: '-10px',
                  marginRight: '-10px',
                }}
              />
            </Tooltip>
          )}
          {inverted ? withInvertedStatistic() : withStatistic()}
        </Card>
      )) ||
        withStatistic()}
    </>
  );
};

export default memo(Statistic);
