import { defaultOrder } from 'helpers/ordenations';
import produce from 'immer';

import notificationActionTypes from './constants';

export const initialState = {
  searchedUsers: [],

  filters: null,
  tableConfigs: {
    page: 1,
    limit: 10,
    sortBy: defaultOrder,
  },

  loadingNotifications: false,
  notifications: [],
  totalPage: 0,
  errorNotifications: null,
};

/* eslint-disable default-case, no-param-reassign */
const notificationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case notificationActionTypes.SET_FILTER:
        draft.filters = action.filters;
        break;
      case notificationActionTypes.SET_TABLE_CONFIGS:
        draft.tableConfigs = action.tableConfigs;
        break;
      case notificationActionTypes.CLEAR_FILTER:
        draft.filters = initialState.filters;
        break;

      case notificationActionTypes.FETCH_NOTIFICATIONS:
        draft.loadingNotifications = true;
        draft.errorNotifications = null;
        break;
      case notificationActionTypes.FETCH_NOTIFICATIONS_SUCCESS:
        draft.notifications = action.response.notifications;
        draft.totalPage = action.response.totalPage;
        draft.loadingNotifications = false;
        break;

      case notificationActionTypes.QUERY_USER_SUCCESS:
        draft.searchedUsers = action.searchedUser;
    }
  });

export default notificationsReducer;
