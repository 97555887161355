import {
  RESET_TEXT_AREA,
  RESET_TEXT_AREA_SUCCESS,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  BLOCK_UNBLOCK_USER,
  ACTIVATE_DEACTIVATE_USER,
  MAKE_OR_REVOKE_ADMINISTRATOR,
  CHANGE_PASSWORD_USER,
  DELETE_USER,
  FETCH_USER_ACTIVITIES,
  FETCH_USER_ACTIVITIES_SUCCESS,
  SET_TABLE_CONFIGS_USER_ACTIVITIES,
  SEND_NOTIFICATION,
  FETCH_USER_BY_EMAIL,
  FETCH_USER_BY_EMAIL_SUCCESS,
  FETCH_ADDRESS_BY_ZIP_CODE,
  FETCH_ADDRESS_BY_ZIP_CODE_SUCCESS,
  FETCH_STATES,
  FETCH_STATES_SUCCESS,
  CLEAR_ITEMS_FORM,
  FETCH_CITIES_BY_STATE,
  FETCH_CITIES_BY_STATE_SUCCESS,
  FETCH_CATEGORIES_TYPES,
  FETCH_CATEGORIES_TYPES_SUCCESS,
  SET_FILTER,
  CLEAR_FILTER,
  SET_PAGINATION_CONFIGS,
  RESET_USER,
} from './constants';

export const resetAccount = userId => ({
  type: RESET_USER,
  payload: userId,
});

export const resetTextArea = () => ({
  type: RESET_TEXT_AREA,
});
export const resetTextAreaSuccess = () => ({
  type: RESET_TEXT_AREA_SUCCESS,
});

export const fetchUser = (userId, profile) => ({
  type: FETCH_USER,
  userId,
  profile,
});

export const fetchUserSuccess = user => ({
  type: FETCH_USER_SUCCESS,
  response: { user },
});

export const blockUser = (userId, isBlocking, profile) => ({
  type: BLOCK_UNBLOCK_USER,
  userId,
  isBlocking,
  profile,
});

export const activateDeactivateUser = (userId, isActive, profile) => ({
  type: ACTIVATE_DEACTIVATE_USER,
  userId,
  isActive,
  profile,
});

export const makeOrRevokeAdministrator = (
  userId,
  password,
  isAdministrator,
  profile,
) => ({
  type: MAKE_OR_REVOKE_ADMINISTRATOR,
  userId,
  password,
  isAdministrator,
  profile,
});

export const changePasswordUser = (userId, newPassword) => ({
  type: CHANGE_PASSWORD_USER,
  userId,
  newPassword,
});

export const sendNotifications = (users, formOptions) => ({
  type: SEND_NOTIFICATION,
  users,
  formOptions,
});

export const deleteUser = (userId, profile) => ({
  type: DELETE_USER,
  userId,
  profile,
});

/*
 #########  Activities  ########
*/
export const fetchUserActivities = userId => ({
  type: FETCH_USER_ACTIVITIES,
  userId,
});

export const fetchUserActivitiesSuccess = (
  userActivities,
  totalUserActivities,
) => ({
  type: FETCH_USER_ACTIVITIES_SUCCESS,
  response: { userActivities, totalUserActivities },
});

export const setTableConfigsUserActivities = tableConfigsUserActivities => ({
  type: SET_TABLE_CONFIGS_USER_ACTIVITIES,
  tableConfigsUserActivities,
});

/*
 #########  User by email  ########
*/
export const fetchUserByEmail = userEmail => ({
  type: FETCH_USER_BY_EMAIL,
  userEmail,
});

export const fetchUserByEmailSuccess = userByEmail => ({
  type: FETCH_USER_BY_EMAIL_SUCCESS,
  userByEmail,
});

/*
 #########  Adress by zipCode  ########
*/
export const fetchAddressByZipCode = zipCode => ({
  type: FETCH_ADDRESS_BY_ZIP_CODE,
  zipCode,
});

export const fetchAddressByZipCodeSuccess = addressByZipCode => ({
  type: FETCH_ADDRESS_BY_ZIP_CODE_SUCCESS,
  addressByZipCode,
});

export const clearItemsForm = () => ({
  type: CLEAR_ITEMS_FORM,
});

/*
 #########  fetch states  ########
*/
export const fetchStates = () => ({
  type: FETCH_STATES,
});

export const fetchStatesSuccess = states => ({
  type: FETCH_STATES_SUCCESS,
  states,
});

/*
 #########  fetch cities  ########
*/
export const fetchCitiesByState = stateId => ({
  type: FETCH_CITIES_BY_STATE,
  stateId,
});

export const fetchCitiesByStateSuccess = cities => ({
  type: FETCH_CITIES_BY_STATE_SUCCESS,
  cities,
});

/*
 #########  fetch categories  ########
*/
export const fetchCategoriesTypes = () => ({
  type: FETCH_CATEGORIES_TYPES,
});

export const fetchCategoriesTypesListSuccess = categories => ({
  type: FETCH_CATEGORIES_TYPES_SUCCESS,
  categories,
});

export const setFilters = filters => ({
  type: SET_FILTER,
  filters,
});

export const clearFilters = () => ({
  type: CLEAR_FILTER,
});

export const setPaginationConfigs = paginationConfigs => ({
  type: SET_PAGINATION_CONFIGS,
  paginationConfigs,
});
