import req from 'utils/request';

import { request } from './formatted-request';

export async function getTags(params) {
  let queryUrl = `/foods?page=${params.page || ''}&limit=${params.limit || ''}`;

  if (params.name) queryUrl = queryUrl.concat(`&query=${params.name}`);
  if (params.minGrams)
    queryUrl = queryUrl.concat(`&minGrams=${params.minGrams}`);
  if (params.maxGrams)
    queryUrl = queryUrl.concat(`&maxGrams=${params.maxGrams}`);

  return request.get(queryUrl, { params });
}

export async function activateOrDeactivateCategory(params) {
  const { isEnabled, categoryId } = params;
  return req().patch(
    isEnabled
      ? `/categories/${categoryId}/deactivate`
      : `/categories/${categoryId}/activate`,
  );
}

export async function updateOrCreateCategory(data) {
  const { id } = data;
  return id
    ? req().put(`/foods/${id}`, { data })
    : req().post('/foods', { data });
}

export async function deleteCategory(params) {
  const { categoryId } = params;
  return req().delete(`/foods/${categoryId}`);
}

export async function getTemplateCsv() {
  return req().get(`/foods/templateUploadCsv`);
}

export async function createFoodsCsv(csv) {
  return req().post('/foods/batch', { data: csv });
}
