import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useTheme } from 'hooks';
import { activeRoute } from 'utils/route';

import { MenuLink } from './Styles';

const SideBar = ({ routes, user }) => {
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  const thisRoute = () => {
    const route = activeRoute(routes, location.pathname);

    return (route && route.key.toString()) || '';
  };

  const theme = useTheme();

  return (
    <>
      <Layout.Sider
        collapsible
        theme="dark"
        collapsed={collapsed}
        onCollapse={() => setCollapsed(!collapsed)}
        breakpoint="lg"
        width="230px"
        style={{
          zIndex: '1',
          marginTop: '65px',
          backgroundColor: theme.background,
          boxShadow: '0 0 100px -30px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Menu
          defaultSelectedKeys={[thisRoute()]}
          mode="inline"
          theme="light"
          style={{
            backgroundColor: theme.background,
            color: theme.regularText,
            border: 'none',
            width: collapsed ? '77px' : '227px',
          }}
        >
          {routes
            .filter(route => !route.parent)
            .filter(route => !route.hideSidebar)
            .filter(
              route =>
                route.accessLevel &&
                route.accessLevel.includes(user.accessLevel),
            )
            .map(route =>
              route.submenu ? (
                <Menu.SubMenu
                  title={
                    <>
                      {route.icon} <span>{route.title}</span>
                    </>
                  }
                  key={route.key}
                >
                  {route.submenu.map(sub => (
                    <Menu.Item key={sub.key}>
                      <MenuLink to={sub.path}>
                        <span>{sub.title}</span>
                      </MenuLink>
                    </Menu.Item>
                  ))}
                </Menu.SubMenu>
              ) : (
                <Menu.Item key={route.key}>
                  <MenuLink to={route.path}>
                    <>
                      {route.icon}
                      <span style={{ marginLeft: '10px' }}>{route.title}</span>
                    </>
                  </MenuLink>
                </Menu.Item>
              ),
            )}
        </Menu>
      </Layout.Sider>
    </>
  );
};

export default SideBar;
