import { PhoneFormatter, CpfFormatter } from '@usemobile_br/usekit';
import moment from 'moment';

import { formatToCurrency } from 'utils/util';

export const formatBankAccount = status => {
  switch (status) {
    case 'conta_corrente':
      return {
        title: 'Conta corrente',
        status,
        colorTag: '#67C23A',
      };
    case 'conta_poupanca':
      return {
        title: 'Conta poupança',
        status,
        colorTag: '#E6A23C',
      };
    case 'conta_corrente_conjunta':
      return {
        title: 'Conta corrente conjunta',
        status,
        colorTag: '#9167D9',
      };
    case 'conta_poupanca_conjunta':
      return {
        title: 'Conta poupança conjunta',
        status,
        colorTag: '#F56C6C',
      };
    default:
      return {
        title: 'Sem dados',
        colorTag: '#909399',
      };
  }
};

export const formatGender = gender =>
  (gender === 'f' || gender === 'm') && gender === 'm'
    ? 'Masculino'
    : 'Feminino';

export const unformatGender = gender =>
  (gender === 'Feminino' || gender === 'Masculino') && gender === 'Masculino'
    ? 'm'
    : 'f';

// Formatting the filter to search for cities by state id
export const formatSearchCitiesByStateFilter = stateId => ({
  filters: {
    eq: {
      state: stateId,
    },
  },
});

// Formats the filter values for the .csv download request
export const filterCsvFormat = profile => ({
  filters: {
    in: {
      roles: [profile],
    },
  },
});

// Formatting the filter to search for data by id
export const searchFilterById = id => ({
  filters: {
    eq: {
      _id: id,
    },
  },
});

// Formats and arranges the attributes of the columns in the .csv file
const genericCsvFormat = ({
  fullName,
  documentId,
  phoneNumber,
  email,
  createdAt,
  genre,
  userName,
}) => ({
  fullName: fullName || '-',
  cpf: (documentId && new CpfFormatter().format(documentId)) || '-',
  phoneNumber: (phoneNumber && new PhoneFormatter().format(phoneNumber)) || '-',
  email: email || '-',
  createdAt: (createdAt && moment(createdAt).format('L')) || '-',
  genre: (genre && formatGender(genre)) || '-',
  userName,
});

export const formatGenericCsv = ({ users: { docs, totalDocs } }) => ({
  dataCsv: docs.map(u => genericCsvFormat(u)),
  totalDocs,
});

export const formatBlockingMessage = (profile, isBlocking) => {
  switch (profile) {
    case 'client':
    case 'clients':
      return `Cliente ${
        isBlocking ? 'bloqueado' : 'desbloqueado'
      } com sucesso!`;
    default:
      return '';
  }
};
