import { Button } from 'antd';
import React from 'react';
import { withRouter } from 'react-router-dom';

import { Error, Typo, ErrorTitle, ErrorText } from './Styles';

const Error404 = ({ history }) => (
  <>
    <Error>
      <div>
        <Typo>
          <ErrorTitle level={2} fontSize="1.8rem">
            Página não encontrada!
          </ErrorTitle>
          <ErrorText type="secondary" fontSize="1.8rem">
            Esta página foi descontinuada, excluída ou não existe...
          </ErrorText>
          <ErrorTitle level={2} fontSize="4rem">
            404 —
          </ErrorTitle>
          <Button
            size="large"
            type="primary"
            ghost
            onClick={() => history.goBack()}
          >
            Voltar
          </Button>
        </Typo>
      </div>
    </Error>
  </>
);

export default withRouter(Error404);
