/* eslint-disable no-nested-ternary */
import {
  UserOutlined,
  LoadingOutlined,
  CameraOutlined,
  UploadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Upload as AntUpload } from 'antd';
import { Field } from 'formik';
import React, { memo, useState, useEffect } from 'react';

const Upload = ({
  menu,
  name,
  baseIcon = <UserOutlined />,
  onUpload,
  validate,
  imageSrc = null,
  square = false,
  accept = 'image/png',
  handleChange,
  ...props
}) => {
  const [tmpImage, setTmpImage] = useState(imageSrc);
  const validateImage = file => false;

  useEffect(() => {
    if (menu) setTmpImage(menu.cover);
  }, [menu]);

  return (
    <Field name={name} validate={validate}>
      {({ form: { setFieldValue, setFieldTouched } }) => (
        <AntUpload
          name={name}
          accept="image/png"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={accept !== 'image/png' || accept === 'audio/*'}
          beforeUpload={validateImage}
          onChange={event => {
            setTmpImage(URL.createObjectURL(event.file));
            handleChange(event);
          }}
          {...props}
        >
          {tmpImage ? (
            <>
              <img
                src={menu.cover ? menu.cover : tmpImage}
                alt="avatar"
                style={{
                  width: '100%',
                }}
              />
            </>
          ) : (
            accept === 'image/png' && (
              <>
                <div
                  style={{
                    height: '100px',
                    width: '100px',
                    padding: '15px 22px',
                    borderRadius: '5px',
                    fontSize: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  <PlusOutlined />
                  Upload
                </div>
              </>
            )
          )}
        </AntUpload>
      )}
    </Field>
  );
};

export default memo(Upload);
