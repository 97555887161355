import req from 'utils/request';
import { getTimezoneInHours } from 'utils/util';

import { request } from './formatted-request';

export async function getUsers(params) {
  return request.get(
    `/users?page=${params.page || ''}&limit=${params.limit ||
      ''}&query=${params.name || ''}&tz=${getTimezoneInHours()}`,
    {
      params,
    },
  );
}

export async function getAdmins(params) {
  return request.get(
    `/users/admin?page=${params.page || ''}&limit=${params.limit ||
      ''}&name=${params.name || ''}&email=${params.email || ''}`,
    { params },
  );
}

export async function getUser(params) {
  const { userId } = params;
  return req().get(`/users/${userId}`);
}

export async function resetUserInfo(id) {
  return req().post(`/users/${id}/reset`, { data: id });
}

export async function getData(params) {
  return req().get(`/dashboard/metrics?tz=${getTimezoneInHours()}`, { params });
}

export async function blockUnblockUser(params) {
  const { isBlocking, userId } = params;
  return req().patch(
    isBlocking
      ? `/admins/users/${userId}/block`
      : `/admins/users/${userId}/unblock`,
  );
}

export async function activateDeactivateUser(params) {
  const { isActive, userId } = params;
  return req().patch(
    isActive
      ? `/admins/users/${userId}/deactivate`
      : `/admins/users/${userId}/activate`,
  );
}

export async function makeOrRevokeAdministrator(params) {
  const { isAdministrator, data, userId } = params;
  return isAdministrator
    ? req().patch(`/admins/users/${userId}/unmake-admin`)
    : req().patch(`/admins/users/${userId}/make-admin`, { data });
}

export async function changePasswordUser(params) {
  const { userId, data } = params;
  return req().patch(`/admins/users/${userId}/change-password`, { data });
}

export async function uploadImage(data) {
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file, data.file.name);
  if (data.file.type === 'image/png') {
    bodyFormData.append('type', 'Cover');
  }
  return req().post('/files', {
    data: bodyFormData,
  });
}

export async function uploadAudio(data) {
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file, data.file.name);
  bodyFormData.append('type', 'Audio');
  return req().post('/files', {
    data: bodyFormData,
  });
}

export async function uploadExercises(data) {
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file, data.file.name);
  bodyFormData.append('type', 'Exercise');
  return req().post('/files', {
    data: bodyFormData,
  });
}

export async function uploadMaps(data) {
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file, data.file.name);
  bodyFormData.append('type', 'IllustratedMap');
  return req().post('/files', {
    data: bodyFormData,
  });
}

export async function uploadCard(data) {
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file, data.file.name);
  bodyFormData.append('type', 'Card');
  return req().post('/files', {
    data: bodyFormData,
  });
}

export async function uploadImageMultiple(data) {
  const bodyFormData = new FormData();
  bodyFormData.append('file', data.file.originFileObj, data.file.name);
  if (data.file.type === 'image/png') {
    bodyFormData.append('type', 'Cover');
  }
  return req().post('/files', {
    data: bodyFormData,
  });
}

export async function checkUserName(params) {
  return request.get('/check-user-name', { params });
}

export async function deleteUser(params) {
  const { userId } = params;
  return req().delete(`users/${userId}`);
}

export async function getAdressByZipCode(params) {
  return request.get('/zip-code', { params });
}

export async function getStates(params) {
  return request.get('/states/list', { params });
}

export async function getCitiesByState(params) {
  return request.get('/cities/list', { params });
}
