import Icon from '@ant-design/icons';
import React from 'react';

import { useTheme } from 'hooks';

import { TagWrapper } from './Styles';

const Tag = ({
  id,
  type,
  icon,
  onClick,
  appendIcon,
  hit,
  solid,
  color,
  round,
  size,
  style,
  children,
}) => {
  const theme = useTheme();
  return (
    <TagWrapper
      id={id}
      onClick={onClick}
      style={style}
      theme={theme}
      hit={hit}
      round={round}
      solid={solid}
      type={type}
      size={size}
      color={color}
    >
      {icon && icon}
      {children && <span>{children}</span>}
      {appendIcon && <Icon className="append-icon" component={appendIcon} />}
    </TagWrapper>
  );
};

export default Tag;
