import { ConnectedRouter } from 'connected-react-router';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Switch from 'react-router-transition-switch';
import { CSSTransition, SwitchTransition } from 'react-transition-group';

import Layout from 'containers/AppLayout';
import reducer from 'containers/AppLayout/reducer';
import NotFoundPage from 'containers/NotFoundPage';
import { useInjectReducer } from 'hooks/injectReducer';

import { ROUTES } from './routes';

const key = 'route';

const Router = ({ history }) => {
  const routes = ROUTES();

  useInjectReducer({ key, reducer });

  return (
    <ConnectedRouter history={history}>
      <Layout routes={routes}>
        <Switch
          render={props => {
            const {
              children,
              location: { pathname },
            } = props;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={pathname}
                  classNames="slide-fadein-right"
                  timeout={300}
                >
                  {children}
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        >
          <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
          {routes.map(
            ({ path, Component, exact, submenu }) =>
              !submenu && (
                <Route path={path} key={path} exact={exact}>
                  <Component />
                </Route>
              ),
          )}
          <Route component={NotFoundPage} />
        </Switch>
      </Layout>
    </ConnectedRouter>
  );
};

export default Router;
