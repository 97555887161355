import { PageHeader, Divider } from 'antd';
import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import Breadcrumb from 'components/element/Breadcrumb';
import { useSettings } from 'hooks';
import { activeRoute } from 'utils/route';

import { ROUTES } from '../../../routes';
import { ContainerViewWrapper, ContainerViewShared } from './Styles';

const ContainerView = ({ title, extra, tags, subtitle, children, isShare }) => {
  const { features } = useSettings();
  const routes = ROUTES(features);
  const match = useRouteMatch();
  const route = activeRoute(routes, match.path);

  return (
    <>
      {(isShare && (
        <ContainerViewShared>
          <Breadcrumb route={route} />
          <Divider />
          {route && (
            <PageHeader
              title={title || route.title}
              onBack={() => window.history.back()}
              extra={[...extra]}
              subTitle={subtitle}
              tags={tags}
            >
              {children}
            </PageHeader>
          )}
        </ContainerViewShared>
      )) || (
        <ContainerViewWrapper>
          <Breadcrumb route={route} />
          <Divider />
          {route && (
            <PageHeader
              title={title || route.title}
              onBack={() => window.history.back()}
              extra={extra}
              tags={tags}
            >
              {children}
            </PageHeader>
          )}
        </ContainerViewWrapper>
      )}
    </>
  );
};

export default ContainerView;
