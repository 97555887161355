import { Layout } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

import Footer from 'components/layout/Footer';
import SideBar from 'components/layout/SideBar';
import TopBar from 'components/layout/TopBar';
import { useSession } from 'hooks';

const Default = ({ user, routes, children }) => {
  const { removeAccess } = useSession();
  const history = useHistory();

  const logout = () => {
    removeAccess();

    history.push('/system/login');
  };

  return (
    <Layout>
      <Layout.Header
        style={{
          position: 'fixed',
          zIndex: 1,
          width: '100%',
          height: '65px',
          backgroundColor: '#222',
        }}
      >
        <TopBar user={user} onLogout={logout} />
      </Layout.Header>
      <Layout>
        <SideBar routes={routes} user={user} />

        <Layout>
          <Layout.Content
            style={{
              marginTop: '65px',
              overflow: 'initial',
            }}
          >
            <div className="content">{children}</div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Default;
