import { Modal as AntModal, Button } from 'antd';
import { isEmpty } from 'lodash';
import qs from 'query-string';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch, useStore } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';

import { useInjectReducer } from 'hooks/injectReducer';
import { useInjectSaga } from 'hooks/injectSaga';
import theme from 'themes';
import {
  getAccessToken,
  isAuthenticated,
  removeAccess,
  getAccessData,
  ACCESS_LEVEL,
} from 'utils/authentication';
import { ordenation, pagination } from 'utils/filter';

export function useSettings() {
  const linguage = 'pt-BR';

  return linguage;
}

export function useTheme() {
  const appTheme = theme();

  return {
    ...appTheme,
    primaryColor: '#F6CB46',
    secondaryColor: '#FFFFFF',
  };
}

export function useSession() {
  return { getAccessToken, isAuthenticated, removeAccess, getAccessData };
}

export function useLoggedUser() {
  return getAccessData();
}

export function useAccessLevel() {
  const { accessLevel } = useLoggedUser();

  const isAdmin = () => accessLevel === ACCESS_LEVEL.ADMINISTRATOR;

  return {
    accessLevel,
    ACCESS_LEVEL,
    checkProfile: { isAdmin },
  };
}
export function useToggle() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return [isOpen, toggle];
}

export function useModal() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => setIsOpen(!isOpen);

  const Modal = ({ children, ...props }) => {
    const { onCancel, visible, ...filteredProps } = props;
    return (
      <div className="Modal">
        <AntModal
          visible={isOpen}
          onCancel={() => toggleModal()}
          okText={props?.okTest ?? 'Enviar'}
          footer={
            props?.footer ?? (
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => {
                    toggleModal();
                  }}
                  key="cancelButton"
                >
                  Cancelar
                </Button>
                <Button
                  type="primary"
                  onClick={() => toggleModal()}
                  key="submitButton"
                >
                  Enviar
                </Button>
              </div>
            )
          }
          {...filteredProps}
        >
          <>{children}</>
        </AntModal>
      </div>
    );
  };
  return [Modal, toggleModal];
}

export function useTableConfig(storeKey) {
  const state = useStore().getState()[storeKey];
  const { tableConfigs } = state || {};
  return tableConfigs;
}

export function useFilter(
  storeKey,
  mainService,
  queryUrl,
  setAction,
  resetAction,
  selfUpdate = true,
) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useStore().getState()[storeKey];
  const { filters, tableConfigs } = state || {};

  useEffect(() => {
    if (selfUpdate) {
      const query = queryUrl(
        qs.parse(location.search, { parseBooleans: true }),
      );
      dispatch(setAction(query.filter));

      dispatch(mainService({ ...filters }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setAction, selfUpdate, dispatch, mainService, queryUrl]);

  const updateFilters = filterValues => {
    history.push(
      `${location.pathname}?${qs.stringify({
        ...filterValues,
      })}`,
    );

    dispatch(setAction(filterValues));

    dispatch(mainService({ ...filters }));
  };

  const resetFilters = () => {
    dispatch(resetAction());

    history.push(
      `${location.pathname}?${qs.stringify({
        ...tableConfigs,
      })}`,
    );

    dispatch(mainService({ ...filters, ...tableConfigs }));
  };

  return [filters, updateFilters, resetFilters];
}

export function useData(uploadedFiles) {
  return uploadedFiles;
}

export function useTableConfigs(
  storeKey,
  mainService,
  queryUrl,
  setAction,
  params,
  selfUpdate = true,
) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useStore().getState()[storeKey];
  const { filters, tableConfigs = {} } = state || {};

  useEffect(() => {
    if (selfUpdate) {
      const query = queryUrl(
        qs.parse(location.search, { parseBooleans: true }),
      );
      dispatch(setAction(query.tableConfigs));

      dispatch(mainService(params, { ...filters, ...tableConfigs }));
    }
  }, [
    location,
    setAction,
    selfUpdate,
    dispatch,
    mainService,
    queryUrl,
    filters,
    params,
    tableConfigs,
  ]);

  const updateTableConfigs = (tc, defaultOrder) => {
    history.push(
      `${location.pathname}?${qs.stringify({
        ...pagination(tc.pag),
        ...ordenation(tc.sorter, defaultOrder),
        ...filters,
      })}`,
    );

    dispatch(
      setAction({
        ...pagination(tc.pag),
        ...ordenation(tc.sorter, defaultOrder),
      }),
    );

    dispatch(mainService({ ...filters, ...tableConfigs }));
  };

  return [tableConfigs, updateTableConfigs];
}

export function useFilterAndTableConfigs(
  storeKey,
  mainService,
  queryUrl,
  { setFilters, clearFilters },
  { setTableConfigs },
) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [filters, updateFilters, resetFilters] = useFilter(
    storeKey,
    mainService,
    queryUrl,
    setFilters,
    clearFilters,
    false,
  );

  const [tableConfigs, updateTableConfigs] = useTableConfigs(
    storeKey,
    mainService,
    queryUrl,
    setTableConfigs,
    undefined,
    false,
  );

  useEffect(() => {
    const query = queryUrl(qs.parse(location.search, { parseBooleans: true }));
    dispatch(setFilters(query.filter));
    dispatch(setTableConfigs(query.tableConfigs));
    dispatch(mainService({ ...filters, ...tableConfigs }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setFilters, setTableConfigs, dispatch, mainService, queryUrl]);

  return [
    { filters, updateFilters, resetFilters },
    { tableConfigs, updateTableConfigs },
  ];
}

export const useReducerAndSaga = (storeKey, reducer, saga) => {
  useInjectReducer({ key: storeKey, reducer });
  useInjectSaga({ key: storeKey, saga });
};

export function useTableConfigsWithoutUrl(
  storeKey,
  mainService,
  setAction,
  selector,
  params,
) {
  const dispatch = useDispatch();
  const tableConfigs = useSelector(selector());

  useEffect(() => {
    dispatch(mainService(params, { ...tableConfigs }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setAction, dispatch, mainService]);

  const updateTableConfigs = (tc, defaultOrder) => {
    dispatch(
      setAction({
        ...pagination(tc.pag),
        ...ordenation(tc.sorter, defaultOrder),
      }),
    );

    dispatch(mainService(params, { ...tableConfigs }));
  };

  return { tableConfigs, updateTableConfigs };
}

export function usePaginationConfigs(
  storeKey,
  mainService,
  queryUrl,
  setAction,
  params,
  selfUpdate = true,
) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useStore().getState()[storeKey];
  const { filters, paginationConfigs = {} } = state || {};

  useEffect(() => {
    if (selfUpdate) {
      const query = queryUrl(
        qs.parse(location.search, { parseBooleans: true }),
      );
      dispatch(setAction(query.paginationConfigs));

      dispatch(mainService(params, { ...filters, ...paginationConfigs }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setAction, selfUpdate, dispatch, mainService, queryUrl]);

  const updatePageConfigs = (tc, defaultOrder) => {
    history.push(
      `${location.pathname}?${qs.stringify({
        ...pagination(tc.pag),
        ...ordenation(tc.sorter, defaultOrder),
        ...filters,
      })}`,
    );

    dispatch(
      setAction({
        ...pagination(tc.pag),
        ...ordenation(tc.sorter, defaultOrder),
      }),
    );

    dispatch(mainService({ ...filters, ...paginationConfigs }));
  };

  return [paginationConfigs, updatePageConfigs];
}
export function useFilterAndUsePageConfigs(
  storeKey,
  mainService,
  queryUrl,
  { setFilters, clearFilters },
  { setPaginationConfigs },
) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [filters, updateFilters, resetFilters] = useFilter(
    storeKey,
    mainService,
    queryUrl,
    setFilters,
    clearFilters,
    false,
  );

  const [paginationConfigs, updatePageConfigs] = usePaginationConfigs(
    storeKey,
    mainService,
    queryUrl,
    setPaginationConfigs,
    undefined,
    false,
  );

  useEffect(() => {
    const query = queryUrl(qs.parse(location.search, { parseBooleans: true }));
    dispatch(setFilters(query.filter));
    dispatch(setPaginationConfigs(query.paginationConfigs));
    dispatch(mainService({ ...filters, ...paginationConfigs }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    setFilters,
    setPaginationConfigs,
    dispatch,
    mainService,
    queryUrl,
  ]);

  return [
    { filters, updateFilters, resetFilters },
    { paginationConfigs, updatePageConfigs },
  ];
}

// dashboard configs
export function useDashboardPaginationConfigs(
  storeKey,
  mainService,
  queryUrl,
  setAction,
  params,
  selfUpdate = true,
) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useStore().getState()[storeKey];
  const { filters, paginationConfigs = {} } = state || {};

  useEffect(() => {
    if (selfUpdate) {
      const query = queryUrl(
        qs.parse(location.search, { parseBooleans: true }),
      );
      dispatch(setAction(query.paginationConfigs));

      dispatch(mainService(params, { ...filters, ...paginationConfigs }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setAction, selfUpdate, dispatch, mainService, queryUrl]);

  const updatePageConfigs = (tc, defaultOrder) => {
    history.push(
      `${location.pathname}?${qs.stringify({
        ...pagination(tc.pag),
        ...ordenation(tc.sorter, defaultOrder),
        ...filters,
      })}`,
    );

    dispatch(
      setAction({
        ...pagination(tc.pag),
        ...ordenation(tc.sorter, defaultOrder),
      }),
    );

    dispatch(mainService({ ...filters, ...paginationConfigs }));
  };

  return [paginationConfigs, updatePageConfigs];
}

export function useFilterAndUseDashboardPageConfigs(
  storeKey,
  mainService,
  queryUrl,
  { setFilters, clearFilters },
  { setPaginationConfigs },
) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [filters, updateFilters, resetFilters] = useFilter(
    storeKey,
    mainService,
    queryUrl,
    setFilters,
    clearFilters,
    false,
  );

  const [paginationConfigs, updatePageConfigs] = useDashboardPaginationConfigs(
    storeKey,
    mainService,
    queryUrl,
    setPaginationConfigs,
    undefined,
    false,
  );

  useEffect(() => {
    const query = queryUrl(qs.parse(location.search, { parseBooleans: true }));
    if (query.filter.endDate !== undefined) {
      dispatch(setFilters(query.filter));
    } else {
      dispatch(setFilters(null));
    }
    if (isEmpty(paginationConfigs)) {
      dispatch(
        setPaginationConfigs({ limit: 10, page: 1, sortBy: '-createdAt' }),
      );
    } else {
      dispatch(setPaginationConfigs(query.paginationConfigs));
    }
    dispatch(mainService({ ...filters, ...paginationConfigs }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    location,
    setFilters,
    setPaginationConfigs,
    dispatch,
    mainService,
    queryUrl,
  ]);

  return [
    { filters, updateFilters, resetFilters },
    { paginationConfigs, updatePageConfigs },
  ];
}

export function useTableConfigsWithoutFilter(
  storeKey,
  mainService,
  queryUrl,
  { setTableConfigs },
) {
  const location = useLocation();
  const dispatch = useDispatch();

  const [tableConfigs, updateTableConfigs] = useTableConfigs(
    storeKey,
    mainService,
    queryUrl,
    setTableConfigs,
    undefined,
    false,
  );

  useEffect(() => {
    const query = queryUrl(qs.parse(location.search, { parseBooleans: true }));
    dispatch(setTableConfigs(query.tableConfigs));
    dispatch(mainService({ ...tableConfigs }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, setTableConfigs, dispatch, mainService, queryUrl]);

  return [{ tableConfigs, updateTableConfigs }];
}
