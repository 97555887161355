import styled from 'styled-components';

export const ContainerViewWrapper = styled.div`
  max-width: 1330px;
  margin: 0 auto;
  padding: 30px;
`;

export const ContainerViewShared = styled.div`
  @media (max-width: 800px) {
    max-width: 100%;
  }
  max-width: 50%;
  margin: 0 auto;
  padding: 30px;
`;
