import { Typography, Row, Col } from 'antd';
import React from 'react';

import FooterWrapper from './Styles';

const { Text } = Typography;

const Footer = () => (
  <FooterWrapper>
    <Row>
      <Col
        span={24}
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          borderRadius: '8px',
          border: '1px solid #B2B2B2',
        }}
      >
        <Text strong>Método Magra Para Sempre 2.0 </Text>
        <Text>&copy; {new Date().getFullYear()}</Text>
        <Text type="secondary"> | {process.env.REACT_APP_VERSION}</Text>
      </Col>
    </Row>
  </FooterWrapper>
);

export default Footer;
