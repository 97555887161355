import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { Row, Col, Drawer, Spin, Skeleton, Button } from 'antd';
import { Formik } from 'formik';
import React, { memo, useState } from 'react';

import { Form, Input, SubmitButton } from 'components';
import { DRAWER_PROPS } from 'utils/layout/components/drawer';
import { MENUS_VALIDATION } from 'validations/menus';

import {
  LabelContainer,
  FormTitleContainer,
  UploadContainer,
  UploadCoverContainer,
  UploadPdfContainer,
  UploadedListContainer,
} from './MenusForm.styles';
import { history } from 'index';

const MenusCreateOrUpdate = ({
  menu,
  loading = false,
  isOpen,
  onClose,
  onSave,
  onReset,
}) => {
  const [selectedCover, setSelectedCover] = useState(null);
  const [file, setFile] = useState(null);
  const [selectedPdf, setSelectedPdf] = useState(null);

  const titleFormProduct = () => {
    if (loading) return <Skeleton paragraph={{ rows: 1 }} />;
    return `${menu?.id ? 'Editar' : 'Novo'} cardápio`;
  };

  const selectedCoverHandler = e => {
    setSelectedCover(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  };
  const selectedPdfHandler = e => setSelectedPdf(e.target.files[0]);

  return (
    <>
      <Drawer
        title={titleFormProduct()}
        visible={isOpen}
        onClose={onClose}
        {...DRAWER_PROPS}
      >
        {!loading ? (
          <Formik
            enableReinitialize
            initialValues={{
              ...menu,
            }}
            validateOnMount
            validationSchema={MENUS_VALIDATION}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const newValue = {
                ...values,
                title: values.title,
                cover: selectedCover,
                pdf: selectedPdf,
              };
              setTimeout(() => {
                if (onSave) onSave({ ...newValue });
                setSubmitting(false);
                resetForm();
              }, 800);
            }}
          >
            <Form layout="vertical">
              <FormTitleContainer>Informações básicas</FormTitleContainer>
              <Row>
                <Col md={24}>
                  <Form.Item required name="title" label="Título do conteúdo">
                    <Input
                      name="title"
                      placeholder="Insira o título do cardápio"
                    />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    required
                    name="caloriesGoal"
                    label="Meta de calorias diárias"
                  >
                    <Input
                      type="number"
                      min="0"
                      name="caloriesGoal"
                      placeholder="Insira a meta de calorias diárias"
                    />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    required
                    name="fatGoal"
                    label="Meta de gorduras diárias"
                  >
                    <Input
                      type="number"
                      min="0"
                      name="fatGoal"
                      placeholder="Insira a meta de gorduras diárias"
                    />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    required
                    name="carbohydratesGoal"
                    label="Meta de carboidratos diárias"
                  >
                    <Input
                      type="number"
                      min="0"
                      name="carbohydratesGoal"
                      placeholder="Insira a meta de carboidratos diárias"
                    />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    required
                    name="proteinGoal"
                    label="Meta de proteínas diárias"
                  >
                    <Input
                      type="number"
                      min="0"
                      name="proteinGoal"
                      placeholder="Insira a meta de proteínas diárias"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <FormTitleContainer>Conteúdo</FormTitleContainer>
              <Row>
                <Col md={24}>
                  <Form.Item required name="cover" label="Capa do conteúdo">
                    <UploadCoverContainer>
                      <input
                        accept="image/*"
                        name="cover"
                        type="file"
                        id="cover"
                        onChange={selectedCoverHandler}
                      />
                      <label
                        tabIndex={0}
                        htmlFor="cover"
                        style={{
                          backgroundImage: `url(${file})`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                        }}
                      >
                        {!file && (
                          <>
                            <span>
                              <PlusOutlined />
                            </span>
                            <span>Inserir</span>
                          </>
                        )}
                      </label>
                    </UploadCoverContainer>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col md={24}>
                  <Form.Item name="pdf" required label="PDF">
                    <UploadPdfContainer>
                      <input
                        accept="application/pdf"
                        name="pdf"
                        type="file"
                        id="pdf"
                        onChange={selectedPdfHandler}
                      />
                      <label htmlFor="pdf" tabIndex={0}>
                        Inserir
                      </label>
                    </UploadPdfContainer>
                    <small>Tamanho máximo do arquivo: 25 MB</small>

                    {selectedPdf && (
                      <UploadedListContainer>
                        <span> {selectedPdf.name} </span>
                        <span>
                          <CloseOutlined
                            onClick={() => setSelectedPdf('')}
                            style={{ cursor: 'pointer', padding: '0 10px' }}
                          />
                        </span>
                      </UploadedListContainer>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 0]} style={{ marginTop: '30px' }} justify="end">
                <Col>
                  <UploadContainer
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Button
                      style={{
                        border: '1px solid #E36926',
                        borderRadius: '8px',
                        marginRight: '15px',
                        color: '#E36926',
                      }}
                      onClick={() => {
                        onClose && onClose();
                        history.go(0);
                      }}
                    >
                      Cancelar
                    </Button>

                    {!selectedPdf ? (
                      <SubmitButton disabled showValidationErrors>
                        Adicionar
                      </SubmitButton>
                    ) : (
                      <SubmitButton showValidationErrors>
                        Adicionar
                      </SubmitButton>
                    )}
                  </UploadContainer>
                </Col>
              </Row>
            </Form>
          </Formik>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default memo(MenusCreateOrUpdate);
