export const LIST_GENDER = [
  { label: 'Masculino', value: 'm' },
  { label: 'Feminino', value: 'f' },
];

export const CHECK_PROFESSIONALS = [
  { label: 'Verificado', value: true },
  { label: 'Não verificado', value: false },
];

export const STATUS_QUOTATIONS = [
  { label: 'Novo', value: 'new' },
  { label: 'Visualizado', value: 'visualized' },
];

export const URGENCY_QUOTATIONS = [
  { label: 'Hoje', value: 'today' },
  { label: 'Essa semana', value: 'week' },
  { label: 'Esse mês', value: 'month' },
  { label: 'A combinar', value: 'combined' },
];

export const LIST_STATUS = [
  { label: 'Compra realizada', value: 'created' },
  { label: 'Produto retidado no local', value: 'taken' },
  { label: 'Produto enviado', value: 'sent' },
  { label: 'Compra cancelada', value: 'cancelled' },
];

export const STARS_LIST = [
  { label: '1 estrela e acima', value: 1 },
  { label: '2 estrelas e acima', value: 2 },
  { label: '3 estrelas e acima', value: 3 },
  { label: '4 estrelas e acima', value: 4 },
  { label: '5 estrelas e acima', value: 5 },
];

export const LIST_VISIBILITY = [
  { label: 'Estado', value: 'state' },
  { label: 'Quilômetro', value: 'km' },
  { label: 'País', value: 'country' },
];

export const LIST_NOTIFICATIONS_OPTIONS = [
  { label: 'Todos', value: 'ALL' },
  { label: 'Assinantes', value: 'SUBSCRIBERS' },
  { label: 'Não assinantes', value: 'NON_SUBSCRIBERS' },
  { label: 'Usuários', value: 'USER' },
];
