import { FileImageOutlined } from '@ant-design/icons';
import { Tooltip, Avatar } from 'antd';
import React, { memo, useState } from 'react';
import Lightbox from 'react-image-lightbox';

import { useToggle } from 'hooks';

const Image = ({
  src,
  size,
  shape = 'square',
  baseIcon = <FileImageOutlined />,
  noImageText = 'Sem imagem',
  ...props
}) => {
  const [isOpen, setIsOpen] = useToggle(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [images, setImages] = useState([]);

  const openImages = index => {
    setImages(src.map(img => img.photoUrl));
    setPhotoIndex(index);
    setIsOpen(true);
  };

  const closeLightobx = () => {
    setImages([]);
    setIsOpen(false);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {src.map((image, index) => (
        <div
          key={index}
          style={{ cursor: 'pointer' }}
          onClick={() => openImages(index)}
        >
          <Tooltip title={image.documentType.name} key={index}>
            <Avatar
              icon={baseIcon}
              src={image?.photoUrl}
              shape={shape}
              size={size}
              title={src ? undefined : noImageText}
              {...props}
            />
          </Tooltip>
        </div>
      ))}
      {isOpen && images.length > 0 && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => closeLightobx()}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default memo(Image);
