import req from 'utils/request';

import { request } from './formatted-request';

export async function getNotifications(params) {
  return request.get(
    `/notifications?page=${params.page}&limit=${params.limit}`,
    {
      params,
    },
  );
}

export async function sendNotification(data) {
  return req().post('/notifications', { data });
}

export async function queryUser(query) {
  return req().get(`/users?page=1&limit=9999&query=${query}`);
}
