import moment from 'moment';

export const formatProductsFilter = filterOptions => {
  const { startDate, endDate, search } = filterOptions || {};
  return {
    search,
    startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
    endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
  };
};

export const formatUserFilter = filterOptions => {
  const { startDate, endDate, search } = filterOptions || {};
  return {
    search,
    startDate: startDate && moment(startDate).format('YYYY-MM-DD'),
    endDate: endDate && moment(endDate).format('YYYY-MM-DD'),
  };
};
