import Cookies from 'js-cookie';

import { APPLICATION_CONFIGS } from 'configs';
import { encrypt, decrypt } from 'utils/encryptor';

const ACCESS_TOKEN = 'acc_token';
const USER_NAME = 'acc_name';
const USER_EMAIL = 'acc_level';
const USER_LEVEL = 'acc_level';

export const ACCESS_LEVEL = {
  ADMINISTRATOR: 'Admin',

  public: () => [ACCESS_LEVEL.ADMINISTRATOR],
};

export const getAccessToken = () => decrypt(Cookies.get(ACCESS_TOKEN));
export const isAuthenticated = () => getAccessToken() !== undefined;

// export const getRoleName = accessLevel => {
//   switch (accessLevel) {
//     case ACCESS_LEVEL.ADMINISTRATOR:
//       return 'Administrador';
//     default:
//       throw new Error(`O tipo de acesso ${accessLevel} não é um acesso válido`);
//   }
// };

export const getAccessData = () => ({
  fullName: decrypt(Cookies.get(USER_NAME)),
  userEmail: decrypt(Cookies.get(USER_EMAIL)),
  accessLevel: decrypt(Cookies.get(USER_LEVEL)),
});

const createCookie = (attribute, value) =>
  Cookies.set(attribute, value, {
    expires: APPLICATION_CONFIGS.AUTHENTICATION_EXPIRATION_TIME,
  });

export const authenticate = ({ user, accessToken }) => {
  createCookie(ACCESS_TOKEN, encrypt(accessToken));
  createCookie(USER_NAME, encrypt(user.name));
  createCookie(USER_EMAIL, encrypt(user.email));
  createCookie(USER_LEVEL, encrypt('Admin'));
};

export const removeAccess = () => {
  Cookies.remove(ACCESS_TOKEN);
  Cookies.remove(USER_NAME);
  Cookies.remove(USER_EMAIL);
  Cookies.remove(USER_LEVEL);
};
