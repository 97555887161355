import * as Yup from 'yup';

import { REQUIRED_MESSAGE } from 'validations/messages';

export const CHALLENGES_VALIDATION = Yup.object().shape({
  name: Yup.string().required(REQUIRED_MESSAGE('Título do desafio')),
  vegetableGoal: Yup.number().required(REQUIRED_MESSAGE('Meta de verduras')),
  fruitGoal: Yup.number().required(REQUIRED_MESSAGE('Meta de frutas')),
  content: Yup.string().required(REQUIRED_MESSAGE('Informações do desafio')),
});
