import { Skeleton, Empty, Card, Row, Col, List, Pagination } from 'antd';
import { isEmpty } from 'lodash';
import React, { memo } from 'react';

import { formatActivityMessagesAccordingAction } from 'utils/formats/activity';

import { DivButton } from './Styles';

export const Activities = ({
  activities,
  loading,
  pagination,
  onUpdatePage,
  smallSize,
  showLowerCase,
}) => {
  const loadMore = (
    <DivButton>
      <Pagination
        simple
        total={pagination.total}
        pageSize={pagination?.page ?? 10}
        current={pagination?.current ?? 1}
        onChange={onUpdatePage}
      />
    </DivButton>
  );

  return (
    <>
      <Row gutter={[20, 20]}>
        <Col md={24}>
          <Card className="card-activities-and-documents">
            <Skeleton loading={loading} active paragraph={{ rows: 5 }}>
              {(isEmpty(activities) && (
                <Empty
                  id="emptyForm"
                  description="Sem atividades"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              )) || (
                <>
                  <List
                    className="list"
                    loadMore={loadMore}
                    dataSource={activities}
                    renderItem={item => (
                      <List.Item className="list-my-activities">
                        <Col md={16}>
                          {formatActivityMessagesAccordingAction(
                            item,
                            smallSize,
                            showLowerCase,
                          )}
                        </Col>
                      </List.Item>
                    )}
                  />
                </>
              )}
            </Skeleton>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default memo(Activities);
