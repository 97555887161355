import {
  Row,
  Col,
  Drawer,
  Spin,
  Skeleton,
  Button,
  Select,
  TimePicker,
} from 'antd';
import { Formik } from 'formik';
import React, { memo, useState, useEffect } from 'react';

import { Form, Input, SubmitButton, DatePicker } from 'components';
import { DRAWER_PROPS } from 'utils/layout/components/drawer';
import { EVENTS_VALIDATION } from 'validations/events';

import { FormTitleContainer } from './ActivitiesForm.styles';
import moment from 'moment';
import { handleTimezone } from 'utils/util';
import { history } from 'index';

const EventsCreateOrUpdate = ({
  product,
  loading = false,
  isOpen,
  onClose,
  onSave,
  onReset,
}) => {
  const [platform, setPlatform] = useState(null),
    [time, setTime] = useState(null),
    [date, setDate] = useState(null);

  useEffect(() => {
    if (product) {
      setDate(moment(product?.date).format('YYYY-MM-DD'));
      setTime(moment(product?.date).format('hh:mm:ss'));
      setPlatform(product?.platform);
    }
  }, [product]);

  const titleFormProduct = () => {
    if (loading) return <Skeleton paragraph={{ rows: 1 }} />;
    return `${product?.id ? 'Editar' : 'Novo'} evento`;
  };

  const disabledPreviousDate = current =>
    current &&
    current <
      moment()
        .subtract(1, 'days')
        .endOf('day');

  const handlePlatform = selectedValue => setPlatform(selectedValue),
    handleTimePicker = (_, timeString) => setTime(timeString),
    handleDatePicker = (_, dateString) => setDate(dateString);

  return (
    <>
      <Drawer
        title={titleFormProduct()}
        visible={isOpen}
        onClose={onClose}
        {...DRAWER_PROPS}
      >
        {!loading ? (
          <Formik
            enableReinitialize
            initialValues={{
              ...product,
            }}
            validateOnMount
            validationSchema={EVENTS_VALIDATION}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const newValues = {
                ...values,
                date: handleTimezone(date, time),
                platform: platform,
              };
              setTimeout(() => {
                if (onSave) onSave({ ...newValues });
                setSubmitting(false);
                resetForm();
              }, 800);
            }}
          >
            <Form layout="vertical">
              <FormTitleContainer>Informações básicas </FormTitleContainer>
              <Row>
                <Col md={24}>
                  <Form.Item
                    className="form__item"
                    required
                    name="title"
                    label="Título do evento"
                  >
                    <Input name="title" placeholder="Insira o nome do evento" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 20]}>
                <Col md={12}>
                  <Form.Item
                    className="form__item"
                    required
                    name="time"
                    label="Horário do evento"
                  >
                    <TimePicker
                      style={{
                        border: '1px solid #707070',
                        borderRadius: '8px',
                        width: '100%',
                      }}
                      name="time"
                      onChange={handleTimePicker}
                      defaultValue={time && moment(time, 'hh:mm:ss')}
                    />
                  </Form.Item>
                </Col>

                <Col md={12}>
                  <Form.Item
                    className="form__item"
                    required
                    name="platform"
                    label="Plataforma do evento"
                  >
                    <Select
                      name="platform"
                      placeholder="Escolher plataforma"
                      onChange={handlePlatform}
                      defaultValue={platform}
                    >
                      <Select.Option value={'INSTAGRAM'}>
                        Instagram
                      </Select.Option>
                      <Select.Option value={'YOUTUBE'}>Youtube</Select.Option>
                      <Select.Option value={'FACEBOOK'}>Facebook</Select.Option>
                      <Select.Option value={'HOTMART'}>Hotmart</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 20]}>
                <Col md={12}>
                  <Form.Item required name="date" label="Data do evento">
                    <DatePicker
                      style={{
                        border: '1px solid #707070',
                        borderRadius: '8px',
                        width: '100%',
                      }}
                      name="date"
                      onChange={handleDatePicker}
                      disabledDate={disabledPreviousDate}
                    />
                  </Form.Item>
                </Col>
                <Col md={12}>
                  <Form.Item
                    className="form__item"                    
                    name="url"
                    label="URL do evento"
                  >
                    <Input name="url" placeholder="Insira a url do evento" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 0]} justify="end">
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Button
                      style={{
                        border: '1px solid #E36926',
                        borderRadius: '8px',
                        marginRight: '15px',
                        color: '#E36926',
                      }}
                      onClick={() => {
                        onClose && onClose();
                        history.go(0);
                      }}
                    >
                      Cancelar
                    </Button>

                    {!platform ? (
                      <SubmitButton disabled showValidationErrors>
                        Adicionar
                      </SubmitButton>
                    ) : !time ? (
                      <SubmitButton disabled showValidationErrors>
                        Adicionar
                      </SubmitButton>
                    ) : !date ? (
                      <SubmitButton disabled showValidationErrors>
                        Adicionar
                      </SubmitButton>
                    ) : (
                      <SubmitButton showValidationErrors>
                        Adicionar
                      </SubmitButton>
                    )}
                  </div>
                </Col>
              </Row>
            </Form>
          </Formik>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default memo(EventsCreateOrUpdate);
