/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-properties */
/* eslint-disable indent */
const hexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const calculateIlluminance = hexColor => {
  const rgb = hexToRgb(hexColor);
  const { r, g, b } = rgb;
  const a = [r, g, b].map(v => {
    v /= 255;
    return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
  });

  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
};

const contrastToWhite = hex => {
  const whiteIlluminance = 1;
  const illuminance = calculateIlluminance(hex);

  return whiteIlluminance / illuminance;
};

export const isContrastOkWithWhite = hex => {
  let color = hex.split('#')[1];
  if (color.length === 3) color += color;

  return contrastToWhite(color) > 4.5;
};

const Colors = {
  white: '#ffffff',
  gray1: '#fafafa',
  gray2: '#f5f5f5',
  gray3: '#EBEEF5',
  gray4: '#E4E7ED',
  gray5: '#DCDFE6',
  gray6: '#C0C4CC',
  gray7: '#909399',
  gray8: '#606266',
  gray9: '#303133',
  gray10: '#242424',
  black: '#000000',

  green: '#67C23A',
  yellow: '#E6A23C',
  red: '#F56C6C',
  blue: '#409EFF',
};

const LightTheme = {
  background: Colors.gray2,
  primaryText: Colors.gray9,
  regularText: Colors.gray8,
  secondaryText: Colors.gray7,
  placeholderText: Colors.gray6,

  baseBorder: Colors.gray5,
  accentBorder: Colors.gray4,
  accentestBorder: Colors.gray3,
};

const ScenarioColors = {
  success: Colors.green,
  warning: Colors.yellow,
  danger: Colors.red,
  info: Colors.blue,
};

const Sizes = {
  basePadding: '20px',

  headerHeigth: 65,

  borderRadius: '4px',
};

const Fonts = {
  baseFontFamily: `'Source Sans Pro', sans-serif`,
  baseFontSize: '15px !default',
};

const Shadows = {
  baseShadow: '3px 3px 6px rgba(0, 0, 0, .1)',
};

export const BestSuitedTheme = (primary, secondary) => {
  if (primary && isContrastOkWithWhite(primary)) return [primary, secondary];

  if (secondary && isContrastOkWithWhite(secondary))
    return [secondary, primary];

  return [primary, secondary];
};

const Theme = () => ({
  ...Colors,
  ...Sizes,
  ...ScenarioColors,
  ...Fonts,
  ...Shadows,
  ...LightTheme,
});

export default Theme;
