import { createSelector } from 'reselect';

import { initialState } from './reducer';

const appLayoutDomain = state => state.appLayout || initialState;

const selectUser = () =>
  createSelector(appLayoutDomain, substate => substate.user);

const selectUserLoader = () =>
  createSelector(appLayoutDomain, substate => substate.loadingUser);

const selectProductsFilters = () =>
  createSelector(appLayoutDomain, substate => substate.filters);

const selectProductPaginationConfigs = () =>
  createSelector(appLayoutDomain, substate => substate.paginationConfigs);

const selectUserActivities = () =>
  createSelector(appLayoutDomain, substate => substate.userActivities);

const selectUserActivitiesLoader = () =>
  createSelector(appLayoutDomain, substate => substate.loadingUserActivities);

const selectTotalUserActivities = () =>
  createSelector(appLayoutDomain, substate => substate.totalUserActivities);

const selectUserActivitiesTableConfigs = () =>
  createSelector(
    appLayoutDomain,
    substate => substate.tableConfigsUserActivities,
  );

const selectUserByEmail = () =>
  createSelector(appLayoutDomain, substate => substate.userByEmail);

const selectUserByEmailLoader = () =>
  createSelector(appLayoutDomain, substate => substate.loadingUserByEmail);

const selectAddressByZipCode = () =>
  createSelector(appLayoutDomain, substate => substate.addressByZipCode);

const selectAddressByZipCodeLoader = () =>
  createSelector(appLayoutDomain, substate => substate.loadingAddressByZipCode);

const selectStates = () =>
  createSelector(appLayoutDomain, substate => substate.states);

const selectStatesLoader = () =>
  createSelector(appLayoutDomain, substate => substate.loadingStates);

const selectCitiesByState = () =>
  createSelector(appLayoutDomain, substate => substate.cities);

const selectCitiesByStateLoader = () =>
  createSelector(appLayoutDomain, substate => substate.loadingCities);

const selectCategoriesTypes = () =>
  createSelector(appLayoutDomain, substate => substate.categories);

const selectCategoriesTypesLoader = () =>
  createSelector(appLayoutDomain, substate => substate.loadingCategories);

export default appLayoutDomain;
export {
  selectUser,
  selectUserLoader,
  selectProductsFilters,
  selectProductPaginationConfigs,
  selectUserActivities,
  selectUserActivitiesLoader,
  selectTotalUserActivities,
  selectUserActivitiesTableConfigs,
  selectUserByEmail,
  selectUserByEmailLoader,
  selectAddressByZipCode,
  selectAddressByZipCodeLoader,
  selectStates,
  selectStatesLoader,
  selectCitiesByState,
  selectCitiesByStateLoader,
  selectCategoriesTypes,
  selectCategoriesTypesLoader,
};
