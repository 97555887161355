import { InputNumber } from 'antd';
import { Field } from 'formik';
import React from 'react';

export const CustomInputNumber = ({
  name,
  children,
  onChange: $onChange,
  onBlur,
  ...restProps
}) => (
  <Field name={name}>
    {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
      <InputNumber
        onChange={(newValue, option) => {
          setFieldValue(name, newValue);
          setTimeout(() => {
            setFieldTouched(name, true, true);
          }, 300);
          if ($onChange) $onChange(newValue, option);
        }}
        onBlur={newValue => {
          setFieldTouched(name);
          if (onBlur) onBlur(newValue);
        }}
        value={value}
        {...restProps}
      >
        {children}
      </InputNumber>
    )}
  </Field>
);
