import {
  MailOutlined,
  DollarOutlined,
  InboxOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { useTheme } from 'hooks';

const { Text } = Typography;
const { primaryColor } = useTheme();

// Formats user activity message
export const formatMessageActivity = (message, showLowerCase) => {
  if (message.includes('<b>')) {
    const messageAction = message.split('<b>');
    const messageUser = messageAction[1].split('</b>');
    return {
      action:
        (showLowerCase === true && messageAction[0].toLowerCase()) ||
        messageAction[0],
      userAction: <Text strong> {messageUser[0]} </Text>,
    };
  }
  return {
    action: message,
    userAction: null,
  };
};

// User activity icon

const IconOutlined = {
  invited: <MailOutlined style={{ color: primaryColor }} />,
  changed: <InboxOutlined style={{ color: primaryColor }} />,
  sold: <DollarOutlined style={{ color: primaryColor }} />,
  purchased: <DollarOutlined style={{ color: primaryColor }} />,
  register: <UserOutlined style={{ color: primaryColor }} />,
};

export const formatActivityIcon = acao => IconOutlined[acao] || '';

const formatActivities = ({ user, object, createdAt, message, ...props }) => ({
  userId: user?._id,
  userName: user?.fullName,
  userLinkPhoto: user?.linkPhoto,
  invitedUserId: object?.data?._id,
  invitedUserName: object?.data?.fullName,
  createdAt,
  message,
  object,
  ...props,
});
export const formatActivitiesResponse = ({
  activities: { docs, totalDocs },
}) => ({
  docs: docs.map(p => formatActivities(p)),
  totalDocs,
});

export const formatActivityMessagesAccordingAction = (
  item,
  smallSize,
  showLowerCase,
) => {
  switch (item.action) {
    case 'invited':
      return (
        <>
          <Text style={{ fontSize: `${smallSize ? '14px' : '18px'}` }}>
            {formatMessageActivity(item.message, showLowerCase).action}
          </Text>
          <Link
            to={`/client/${item?.invitedUserId}`}
            style={{ fontSize: `${smallSize ? '14px' : '18px'}` }}
          >
            {item?.invitedUserName}
          </Link>
        </>
      );
    case 'changed':
      return (
        <>
          <Text style={{ fontSize: `${smallSize ? '14px' : '18px'}` }}>
            {formatMessageActivity(item.message, showLowerCase).action}
          </Text>
          <Link
            to={`/product/${item?.object?.data?._id}`}
            style={{ fontSize: `${smallSize ? '14px' : '18px'}` }}
          >
            {item?.object?.data?.title}
          </Link>
        </>
      );
    default:
      return (
        <Text style={{ fontSize: `${smallSize ? '14px' : '18px'}` }}>
          {formatMessageActivity(item.message, showLowerCase).action}
          {formatMessageActivity(item.message, showLowerCase).userAction}
        </Text>
      );
  }
};
