export const filter = ({ page = 1, limit = 10, order, filters }) => ({
  page,
  limit,
  order,
  ...filters,
});

export const ordenation = (sorter, defaultSort) => {
  if (!sorter || !sorter.order) return { sortBy: defaultSort };

  if (sorter.order === 'ascend') return { sortBy: `-${sorter.field}` };

  return { sortBy: sorter.field };
};

export const pagination = pagin => ({
  page: pagin.current || 1,
  limit: pagin.pageSize || 10,
});
