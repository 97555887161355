/* Created to simulate on the frontend a GET that is
  received as a POST on the backend */

import req from 'utils/request';

const formatGetOptions = options => {
  const { params, ...getOptions } = options;
  return getOptions;
};

const composeUrl = url => `${url}`;

export const request = {
  get: (url, options) =>
    req().get(composeUrl(url), {
      data: options?.params ?? {},
      ...formatGetOptions(options),
    }),
};
