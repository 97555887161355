import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  float: right;
  min-height: 65px;
  padding: 0px 20px;
  background-color: transparent;
`;

export const Image = styled.img`
  height: 43px;
  margin: 13px 20px;
  cursor: pointer;
`;

export const StyleText = styled(Text)`
  color: white;
  font-size: 16px;
  padding-right: 20px;
`;
