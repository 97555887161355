const notificationActionTypes = {
  SET_FILTER: 'SET_FILTER',
  CLEAR_FILTER: 'CLEAR_FILTER',
  SET_TABLE_CONFIGS: 'SET_TABLE_CONFIGS',

  FETCH_NOTIFICATIONS: 'FETCH_NOTIFICATIONS',
  FETCH_NOTIFICATIONS_SUCCESS: 'FETCH_NOTIFICATIONS_SUCCESS',

  SEND_NOTIFICATION_USERS: 'SEND_NOTIFICATION_USERS',

  QUERY_USER: 'QUERY_USER',
  QUERY_USER_SUCCESS: 'QUERY_USER_SUCCESS',
};

export default notificationActionTypes;
