import { Row, List, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { Avatar } from 'components';

const { Text } = Typography;

export const ViewCommentsLikes = ({
  Modal,
  numberOfLikes,
  likes,
  noLinkAction,
}) => (
  <Modal
    keyboard={false}
    maskClosable={false}
    title={`${numberOfLikes} curtidas`}
    footer={null}
  >
    <>
      <Row gutter={[20, 20]}>
        <List
          itemLayout="horizontal"
          dataSource={likes}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                avatar={
                  <Avatar
                    className="avatar-likes"
                    src={item?.user?.linkPhoto}
                  />
                }
                title={
                  (noLinkAction && <Text>{item.user.fullName}</Text>) || (
                    <Link to={`/client/${item?.user?._id}`}>
                      {item?.user?.fullName}
                    </Link>
                  )
                }
              />
            </List.Item>
          )}
        />
      </Row>
    </>
  </Modal>
);

export default ViewCommentsLikes;
