import { InfoCircleOutlined } from '@ant-design/icons';
import { Typography, Tooltip } from 'antd';
import { isDate } from 'lodash';
import moment from 'moment';
import numbro from 'numbro';
import React from 'react';

import { formatOutput } from 'utils/layout/description';

const { Text } = Typography;

const ViewTuple = ({
  label,
  value,
  format,
  info,
  horizontal,
  signal,
  alignRight,
}) => (
  <>
    {!horizontal && (
      <>
        <div
          style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}
        >
          <Text type="secondary">{label}</Text>
          {info && (
            <Tooltip title={info}>
              <InfoCircleOutlined
                style={{ fontSize: '12px', marginLeft: '5px' }}
              />
            </Tooltip>
          )}
        </div>
        {format && (
          <Text strong>
            {isDate(value)
              ? moment(value).format(format)
              : numbro(value).format(format)}
          </Text>
        )}
        {!format && (
          <Text strong>
            {formatOutput(value)}
            {signal}
          </Text>
        )}
      </>
    )}

    {horizontal && (
      <>
        <div style={{ marginBottom: '7px' }}>
          <Text>{label} </Text>
          {format && (
            <Text strong style={alignRight && { float: 'right' }}>
              {isDate(value)
                ? moment(value).format(format)
                : numbro(value).format(format)}
            </Text>
          )}
          {!format && (
            <Text strong style={alignRight && { float: 'right' }}>
              {formatOutput(value)}
              {signal}
            </Text>
          )}
        </div>
      </>
    )}
  </>
);

export default ViewTuple;
