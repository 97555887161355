import { Button } from 'antd';
import styled from 'styled-components';

export const UserDetailsContainer = styled.div`
  position: relative;
  background-color: #fff;
  border: 1px solid #b2b2b2;
  border-radius: 8px;
`;

export const ResetButton = styled(Button)`
  font-family: inherit;
  font-size: 17px;
  line-height: 19px;
  color: #ffffff;

  width: 164px;
  height: 52px;
  background: #ff5252;
  border-radius: 8px;

  position: absolute;
  top: -60px;
  right: 0;

  &:hover {
    background-color: #e64a4a !important;
  }
`;

export const UserOverviewContainer = styled.div`
  background-color: #f7b267;
  border-radius: 8px;
  padding: 10px 20px;

  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
`;

export const SectionContainer = styled.section`
  width: 95%;
  margin: 0 auto;
  border-bottom: 1px solid #707070;
  padding: 10px;

  display: flex;
  align-items: center;
  gap: 50px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }

  div {
    color: #707070;
  }

  span {
    display: block;
    color: #262626;

    &.highlight {
      color: #e36926;
    }
  }
`;

export const ComparisonContainer = styled.div``;

export const IsAllowedContainer = styled.div`
  width: 100%;
`;

export const FigureContainer = styled.figure`
  width: 75%;
  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  img {
    max-width: 50%;
  }
`;

export const RewardsContainer = styled.div`
  width: 100%;
`;

export const DisplayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  div {
    width: 100%;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }

  img {
    max-width: 80px;
  }
`;
