export const RESET_TEXT_AREA = 'AppLayout/RESET_TEXT_AREA';
export const RESET_TEXT_AREA_SUCCESS = 'AppLayout/RESET_TEXT_AREA_SUCCESS';

export const FETCH_USER = 'AppLayout/FETCH_USER';
export const FETCH_USER_SUCCESS = 'AppLayout/FETCH_USER_SUCCESS';

export const BLOCK_UNBLOCK_USER = 'AppLayout/BLOCK_UNBLOCK_USER';

export const ACTIVATE_DEACTIVATE_USER = 'AppLayout/ACTIVATE_DEACTIVATE_USER';

export const MAKE_OR_REVOKE_ADMINISTRATOR =
  'AppLayout/MAKE_OR_REVOKE_ADMINISTRATOR';

export const CHANGE_PASSWORD_USER = 'AppLayout/CHANGE_PASSWORD_USER';

export const SEND_NOTIFICATION = 'AppLayout/SEND_NOTIFICATION';

export const DELETE_USER = 'AppLayout/DELETE_USER';

// Activities
export const FETCH_USER_ACTIVITIES = 'AppLayout/FETCH_USER_ACTIVITIES';
export const FETCH_USER_ACTIVITIES_SUCCESS =
  'AppLayout/FETCH_USER_ACTIVITIES_SUCCESS';
export const SET_TABLE_CONFIGS_USER_ACTIVITIES =
  'AppLayout/SET_TABLE_CONFIGS_USER_ACTIVITIES';

// Search user by email
export const FETCH_USER_BY_EMAIL = 'AppLayout/FETCH_USER_BY_EMAIL';
export const FETCH_USER_BY_EMAIL_SUCCESS =
  'AppLayout/FETCH_USER_BY_EMAIL_SUCCESS';

// Fetch adress by zipCode(CEP)
export const FETCH_ADDRESS_BY_ZIP_CODE = 'AppLayout/FETCH_ADDRESS_BY_ZIP_CODE';
export const FETCH_ADDRESS_BY_ZIP_CODE_SUCCESS =
  'AppLayout/FETCH_ADDRESS_BY_ZIP_CODE_SUCCESS';

export const CLEAR_ITEMS_FORM = 'AppLayout/CLEAR_ITEMS_FORM';

// Fetch states
export const FETCH_STATES = 'AppLayout/FETCH_STATES';
export const FETCH_STATES_SUCCESS = 'AppLayout/FETCH_STATES_SUCCESS';

// Fetch cities
export const FETCH_CITIES_BY_STATE = 'AppLayout/FETCH_CITIES_BY_STATE';
export const FETCH_CITIES_BY_STATE_SUCCESS =
  'AppLayout/FETCH_CITIES_BY_STATE_SUCCESS';

// Fetch categories list for filters
export const FETCH_CATEGORIES_TYPES = 'AppLayout/FETCH_CATEGORIES_TYPES';
export const FETCH_CATEGORIES_TYPES_SUCCESS =
  'AppLayout/FETCH_CATEGORIES_TYPES_SUCCESS';

// Add coins for professionals and clients
export const ADD_COINS = 'AppLayout/ADD_COINS';

export const SET_FILTER = 'AppLayout/SET_FILTER';
export const CLEAR_FILTER = 'AppLayout/CLEAR_FILTER';

export const SET_PAGINATION_CONFIGS = 'AppLayout/SET_PAGINATION_CONFIGS';

export const RESET_USER = 'AppLayout/RESET_USER';
