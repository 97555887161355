import moment from 'moment';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';
export const Environment = {
  PRODUCTION: 'production',
  STAGING: 'staging',
  DEVELOPMENT: 'development',
};

export const DATE_FORMAT_DD_MM_YYYY = 'DD/MM/YYYY';

export const MAX_LIMIT = 99999999;

export const TODAY = moment(new Date()).format('YYYY-MM-DD');
