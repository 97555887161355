import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Drawer, Spin, Skeleton, Button } from 'antd';
import { Formik } from 'formik';
import React, { memo, useState, useEffect } from 'react';

import { Form, Input, SubmitButton } from 'components';
import { DRAWER_PROPS } from 'utils/layout/components/drawer';
import { RECIPES_VALIDATION } from 'validations/recipes';

import {
  LabelContainer,
  FormTitleContainer,
  UploadContainer,
  UploadCoverContainer,
} from './RecipesForm.styles';
import { history } from 'index';

const RecipesCreateOrUpdate = ({
  product,
  loading = false,
  isOpen,
  onClose,
  onSave,
}) => {
  const [selectedCover, setSelectedCover] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (product) setFile(product.cover);
  }, [product]);

  const titleFormProduct = () => {
    if (loading) return <Skeleton paragraph={{ rows: 1 }} />;
    return `${product?.id ? 'Editar' : 'Nova'} receita`;
  };

  const selectedCoverHandler = e => {
    setSelectedCover(e.target.files[0]);
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  return (
    <>
      <Drawer
        style={{ zIndex: '9999' }}
        title={titleFormProduct()}
        visible={isOpen}
        onClose={onClose}
        {...DRAWER_PROPS}
      >
        {!loading ? (
          <Formik
            enableReinitialize
            initialValues={{
              ...product,
            }}
            validateOnMount
            validationSchema={RECIPES_VALIDATION}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const newValue = {
                ...values,
                cover: selectedCover || product.cover,
                servings: values.servings || 1,
                preparationTime: values.preparationTime || 0,
              };
              setTimeout(() => {
                if (onSave) onSave({ ...newValue });
                setSubmitting(false);
                resetForm();
              }, 800);
              setFile(null);
            }}
          >
            <Form layout="vertical">
              <FormTitleContainer>Informações básicas</FormTitleContainer>
              <Row gutter={[20, 20]}>
                <Col md={12} style={{ marginBottom: '-30px' }}>
                  <Form.Item
                    className="form__item"
                    required
                    name="title"
                    label="Título do conteúdo"
                  >
                    <Input
                      name="title"
                      placeholder="Insira o título do cardápio"
                    />
                  </Form.Item>
                </Col>

                <Col md={12} style={{ marginBottom: '-30px' }}>
                  <Form.Item
                    className="form__item"
                    name="servings"
                    label="Número de porções"
                  >
                    <Input
                      type="number"
                      min="0"
                      name="servings"
                      placeholder="Insira o nº de porções"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 20]} style={{ marginTop: '30px' }}>
                <Col md={12}>
                  <Form.Item
                    className="form__item"
                    required
                    name="cover"
                    label="Capa da receita"
                  >
                    <UploadContainer>
                      <UploadCoverContainer>
                        <input
                          accept="image/*"
                          name="cover"
                          type="file"
                          id="cover"
                          onChange={selectedCoverHandler}
                        />
                        <label
                          htmlFor="cover"
                          style={{
                            backgroundImage: `url(${file})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                          }}
                        >
                          {!file && (
                            <>
                              <span>
                                <PlusOutlined />
                              </span>
                              <span>Inserir</span>
                            </>
                          )}
                        </label>
                      </UploadCoverContainer>
                    </UploadContainer>
                  </Form.Item>
                </Col>

                <Col md={12}>
                  <Form.Item
                    className="form__item"
                    name="preparationTime"
                    label="Tempo de preparo"
                  >
                    <Input
                      type="number"
                      min="0"
                      name="preparationTime"
                      placeholder="Insira o tempo de preparo"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <FormTitleContainer>Conteúdo</FormTitleContainer>
              <Row gutter={[20, 0]}>
                <Col md={24}>
                  <Form.Item
                    className="form__item"
                    required
                    name="ingredients"
                    label="Ingredientes"
                  >
                    <Input.TextArea
                      name="ingredients"
                      placeholder="Insira todos os ingredientes utilizados na receita"
                    />
                  </Form.Item>
                </Col>

                <Col md={24}>
                  <Form.Item
                    className="form__item"
                    required
                    name="preparation"
                    label="Modo de preparo"
                  >
                    <Input.TextArea
                      name="preparation"
                      placeholder="Descreva todo o passo a passo da receita"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 0]} style={{ marginTop: '30px' }} justify="end">
                <Col>
                  <UploadContainer
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Button
                      style={{
                        border: '1px solid #E36926',
                        borderRadius: '8px',
                        marginRight: '15px',
                        color: '#E36926',
                      }}
                      onClick={() => {
                        onClose && onClose();
                        history.go(0);
                      }}
                    >
                      Cancelar
                    </Button>

                    {!selectedCover ? (
                      <SubmitButton disabled showValidationErrors>
                        Adicionar
                      </SubmitButton>
                    ) : (
                      <SubmitButton showValidationErrors>
                        Adicionar
                      </SubmitButton>
                    )}
                  </UploadContainer>
                </Col>
              </Row>
            </Form>
          </Formik>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default memo(RecipesCreateOrUpdate);
