import styled from 'styled-components';

export const FormTitleContainer = styled.h2`
  font-size: 24px;
  font-weight: 500;
`;

export const LabelContainer = styled.p`
  font-family: inherit;
  font-weight: 400;
  font-size: 17px;
  color: #707070;
`;

export const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
