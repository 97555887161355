import { Tooltip, Modal } from 'antd';
import { capitalize } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { resetAccount } from 'containers/AppLayout/actions';
import { formatDate } from 'utils/util';

import { ReactComponent as EmptyBox } from '../../../assets/empty-box.svg';
import {
  UserDetailsContainer,
  UserOverviewContainer,
  SectionContainer,
  ComparisonContainer,
  IsAllowedContainer,
  FigureContainer,
  RewardsContainer,
  DisplayContainer,
  ResetButton,
} from './Styles';

export const UserData = ({ userData }) => {
  const dispatch = useDispatch();

  const [medals, setMedals] = useState(null);
  const [trophies, setTrophies] = useState([]);

  useEffect(() => {
    if (userData) setMedals(userData.medals);
    if (userData) setTrophies(userData.trophies);
  }, [dispatch, userData]);

  const confirm = () => {
    Modal.confirm({
      title: `Tem certeza que deseja resetar a conta de ${userData.name}?`,
      content:
        'A conta será resetada para a etapa do chat. Orientar a usuária para deslogar da conta e realizar o login novamente.',
      okText: 'Resetar conta',
      cancelText: 'Voltar',
      onOk: () => {
        dispatch(resetAccount(userData.id));
      },
    });
  };

  const DISPLAYING = 4;
  return (
    <>
      <UserDetailsContainer>
        <ResetButton className="reset-btn" onClick={confirm}>
          Resetar conta
        </ResetButton>
        <UserOverviewContainer>
          <img
            src={userData.profilePhoto}
            alt={`Foto de perfil de ${userData.name}.`}
          />
          <span>{capitalize(userData.name)}</span>
        </UserOverviewContainer>

        <SectionContainer>
          <div>
            E-mail: <span className="highlight">{userData.email}</span>
          </div>
          <div>
            Data de cadastro: <span>{formatDate(userData.createdAt)} </span>
          </div>
        </SectionContainer>

        <SectionContainer>
          <div>
            Entrou no aplicativo com: <span>{userData.weightOriginal}KG</span>
          </div>

          <div>
            Atualmente está com: <span>{userData.lastWeight}KG</span>
          </div>
        </SectionContainer>

        <SectionContainer>
          <RewardsContainer>
            Troféus e medalhas
            <DisplayContainer>
              <div>
                {trophies ? (
                  <>
                    {trophies
                      .filter((medal, idx) => idx < DISPLAYING)
                      .map(medal => (
                        <Tooltip key={medal.id} title={medal.description}>
                          <img
                            key={medal.id}
                            src={medal.icon}
                            alt={medal.description}
                          />
                        </Tooltip>
                      ))}
                    {trophies.length >= DISPLAYING ? (
                      <p>+ {trophies.length - DISPLAYING}</p>
                    ) : (
                      <span>Sem troféus para mostrar... ainda.</span>
                    )}
                  </>
                ) : (
                  <span>Sem troféus para mostrar... ainda.</span>
                )}
              </div>
              <div>
                {medals ? (
                  <>
                    {medals.slice(0, DISPLAYING).map(medal => (
                      <Tooltip key={medal.id} title={medal.description}>
                        <img src={medal.icon} alt={medal.description} />
                      </Tooltip>
                    ))}

                    {medals.length <= DISPLAYING ? (
                      <span />
                    ) : medals.length >= DISPLAYING ? (
                      <p>+ {medals.length - DISPLAYING}</p>
                    ) : (
                      <span>Sem medalhas para mostrar... ainda.</span>
                    )}
                  </>
                ) : (
                  <span>Sem medalhas para mostrar... ainda.</span>
                )}
              </div>
            </DisplayContainer>
          </RewardsContainer>
        </SectionContainer>

        <SectionContainer>
          <ComparisonContainer>
            <p>Fotos de comparação:</p>
            {userData.allowShowPhotos ? (
              <IsAllowedContainer>
                <FigureContainer>
                  <img src={userData?.photos?.first} alt="Foto inicial" />
                  <img src={userData?.photos?.last} alt="Resultado final" />
                </FigureContainer>
              </IsAllowedContainer>
            ) : (
              <IsAllowedContainer>
                <EmptyBox />
                <span>
                  A usuária não permitiu o compartilhamento de suas fotos com a
                  equipe MPS
                </span>
              </IsAllowedContainer>
            )}
          </ComparisonContainer>
        </SectionContainer>
      </UserDetailsContainer>
    </>
  );
};

export default UserData;
