import React from 'react';
import styled from 'styled-components';
import { CloseOutlined } from '@ant-design/icons';

export const UploadedListContainer = styled.div`
  padding: 10px 7px;

  p {
    width: 100%;
    margin: 0;
    color: #e36926;
    font-size: 15px;
    transition: 0.3s;
    padding: 0 7px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      background-color: #f3f3f3;
    }
  }

  &:last-child {
    margin-bottom: 20px;
  }

  &:hover {
    color: #b6541e;
  }
`;

const UploadedListItem = ({ item, handleItemDelete }) => {
  return (
    <UploadedListContainer>
      <p>
        <span>{item.name || 'Arquivo'} </span>
        {handleItemDelete && (
          <span>
            <CloseOutlined
              style={{ padding: '0 10px' }}
              onClick={() => handleItemDelete(item)}
              tabIndex="0"
            />
          </span>
        )}
      </p>
    </UploadedListContainer>
  );
};

export default UploadedListItem;
