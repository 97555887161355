import { LogoutOutlined } from '@ant-design/icons';
import { Typography, Dropdown, Menu, Button, Avatar } from 'antd';
import React from 'react';

import { getRoleName } from 'utils/authentication';
import history from 'utils/history';
import { genderFriendly } from 'utils/util';

import { TopBarWrapper } from './Styles';

const { Text } = Typography;

const menu = ({ user, onLogout }) => (
  <Menu>
    {/* <Menu.Item className="menu-info" key="0" style={{ display: 'flex' }}>
      <Text style={{ paddingRight: '5px' }}>
        {genderFriendly({ text: 'Bem-vindo', gender: user?.gender })}
      </Text>
      <Text ellipsis strong>
        {user.fullName}
      </Text>
    </Menu.Item>

    <Menu.Item className="menu-info" key="1" style={{ display: 'flex' }}>
      <Text style={{ paddingRight: '5px' }}> Perfil: </Text>
      <Text ellipsis strong>
        {getRoleName(user.accessLevel)}
      </Text>
    </Menu.Item>

    <Menu.Divider /> */}

    <Menu.Item id="button-logout" onClick={() => onLogout()} key="4">
      <Button type="link" style={{ color: 'black', borderRadius: '10px' }}>
        <LogoutOutlined /> Sair
      </Button>
    </Menu.Item>
  </Menu>
);

const TopBar = ({ user, onLogout }) => (
  <>
    <img
      src="/logoMps.svg"
      alt="Logo"
      className="logo"
      style={{
        height: '43px',
        margin: '13px 20px',
        cursor: 'pointer',
      }}
      onClick={() => history.push('/')}
    />
    <TopBarWrapper>
      <Dropdown
        style={{ width: '500px' }}
        overlay={menu({ user, onLogout })}
        trigger={['click']}
      >
        <Avatar size="large" src={user.linkPhoto} />
      </Dropdown>
    </TopBarWrapper>
  </>
);
export default TopBar;
