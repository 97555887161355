import styled from 'styled-components';

export const MultiAvatar = styled.div`
  display: flex;
  flex-direction: column;

  a {
    &:not(:first-child) {
      margin-top: -20px;
    }

    > span {
      flex-shrink: 0;
      border: 3px solid #fff;
    }
  }
`;
