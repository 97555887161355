import styled from 'styled-components';

export const DownloadAppDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Image = styled.img`
  height: 63px;
  margin: 13px 20px;
  cursor: pointer;
`;
