import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const MenuLink = styled(Link)`
  display: flex !important;
  align-items: center;
  user-select: none;
  color: inherit !important;
  
`;
