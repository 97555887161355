import { Button } from 'antd';
import React from 'react';
import { useMediaQuery } from 'react-responsive';

import history from 'utils/history';

import { TopBarWrapper, Image, StyleText } from './Styles';

const TopBarSharedPage = ({ onDonloadApp }) => {
  const showMessage = useMediaQuery({ query: '(max-width: 650px)' });
  return (
    <>
      <Image
        src="/logoMps.svg"
        alt="Logo"
        className="logo"
        onClick={() => history.push('/')}
      />
      <TopBarWrapper>
        {!showMessage && (
          <StyleText>Cadastre-se e faça parte do Método Magra Pra Sempre!</StyleText>
        )}
        <Button
          type="primary"
          onClick={onDonloadApp}
          style={{ padding: `${(showMessage && '10px') || '20px'}` }}
        >
          Baixar aplicativo
        </Button>
      </TopBarWrapper>
    </>
  );
};
export default TopBarSharedPage;
