import { Layout } from 'antd';
import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import { ContainerView, DownloadApp, TopBarSharedPage } from 'components';
import Footer from 'components/layout/Footer';
import { useModal } from 'hooks';

import { Header, Content } from './Styles';

const viewName = 'Publicação compartilhada';

export const SharedPage = ({ children, isShare }) => {
  const [ModalDownloadApp, toggleModalDownloadApp] = useModal();

  const onDonloadApp = () => {
    toggleModalDownloadApp();
  };

  return (
    <>
      <Layout>
        <Header>
          <TopBarSharedPage onDonloadApp={onDonloadApp} />
        </Header>
        <Layout>
          <Layout>
            <Content>
              {(isShare && (
                <div id="share">
                  <Helmet title={viewName} />
                  <ContainerView isShare={isShare}>{children}</ContainerView>
                </div>
              )) || <div>{children}</div>}
            </Content>
            <Layout.Footer id="footer-share">
              <Footer />
            </Layout.Footer>
          </Layout>
        </Layout>
      </Layout>
      <DownloadApp
        title="Baixe o Magra Pra Sempre no seu celular!"
        Modal={ModalDownloadApp}
      />
    </>
  );
};

export default memo(SharedPage);
