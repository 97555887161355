import { defaultOrder } from 'helpers/ordenations';
import produce from 'immer';

import {
  RESET_TEXT_AREA,
  RESET_TEXT_AREA_SUCCESS,
  FETCH_USER,
  FETCH_USER_SUCCESS,
  FETCH_USER_ACTIVITIES,
  FETCH_USER_ACTIVITIES_SUCCESS,
  SET_TABLE_CONFIGS_USER_ACTIVITIES,
  FETCH_USER_BY_EMAIL,
  FETCH_USER_BY_EMAIL_SUCCESS,
  FETCH_ADDRESS_BY_ZIP_CODE,
  FETCH_ADDRESS_BY_ZIP_CODE_SUCCESS,
  FETCH_STATES,
  FETCH_STATES_SUCCESS,
  CLEAR_ITEMS_FORM,
  FETCH_CITIES_BY_STATE,
  FETCH_CITIES_BY_STATE_SUCCESS,
  FETCH_CATEGORIES_TYPES,
  FETCH_CATEGORIES_TYPES_SUCCESS,
  SET_FILTER,
  SET_PAGINATION_CONFIGS,
  CLEAR_FILTER,
} from './constants';

export const initialState = {
  resetTextArea: false,

  user: {},
  loadingUser: false,
  errorUser: null,

  userActivities: [],
  loadingUserActivities: false,
  errorUserActivities: null,
  totalUserActivities: 0,
  tableConfigsUserActivities: {
    page: 1,
    limit: 10,
    sortBy: defaultOrder,
  },

  userQuotations: [],
  loadingUserQuotations: false,
  errorUserQuotations: null,
  totalUserQuotations: 0,
  tableConfigsUserQuotations: {
    page: 1,
    limit: 10,
    sortBy: defaultOrder,
  },

  userPurchases: [],
  loadingUserPurchases: false,
  errorUserPurchases: null,
  totalUserPurchases: 0,
  tableConfigsUserPurchases: {
    page: 1,
    limit: 10,
    sortBy: defaultOrder,
  },

  userPublications: [],
  loadingUserPublications: false,
  errorUserPublications: null,
  pagePublications: 0,
  totalPagesPublications: 0,

  likes: [],
  loadingLikes: false,
  errorLikes: null,

  ratingPublications: [],
  loadingRatingPublications: false,
  errorRatingPublications: null,
  pageRatingPublications: 0,
  totalPagesRatingPublications: 0,

  userByEmail: {},
  loadingUserByEmail: false,
  errorUserByEmail: null,

  addressByZipCode: {},
  loadingAddressByZipCode: false,
  errorAddressByZipCode: null,

  states: [],
  loadingStates: false,
  errorStates: null,

  cities: [],
  loadingCities: false,
  errorCities: null,

  categories: [],
  loadingCategories: false,
  errorCategories: null,

  quotationAcceptedBy: [],
  loadingQuotationAcceptedBy: false,
  errorQuotationAcceptedBy: null,
  totalQuotationAcceptedBy: 0,
  tableQuotationAcceptedByConfigs: {
    page: 1,
    limit: 10,
    order: defaultOrder,
  },

  commentsLikes: [],
  totalCommentsLikes: 0,
  loadingCommentsLikes: false,
  errorCommentsLikes: null,

  filters: null,

  paginationConfigs: {
    page: 1,
    limit: 5,
    sortBy: defaultOrder,
  },
};

/* eslint-disable default-case, no-param-reassign */
const appLayoutReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case RESET_TEXT_AREA:
        draft.resetTextArea = true;
        break;
      case RESET_TEXT_AREA_SUCCESS:
        draft.resetTextArea = false;
        break;

      case FETCH_USER:
        draft.loadingUser = true;
        draft.errorUser = null;
        break;
      case FETCH_USER_SUCCESS:
        draft.user = action.response.user;
        draft.loadingUser = false;
        break;

      case FETCH_USER_ACTIVITIES:
        draft.loadingUserActivities = true;
        draft.errorUserActivities = null;
        break;
      case FETCH_USER_ACTIVITIES_SUCCESS:
        draft.userActivities = action.response.userActivities;
        draft.totalUserActivities = action.response.totalUserActivities;
        draft.loadingUserActivities = false;
        break;
      case SET_TABLE_CONFIGS_USER_ACTIVITIES:
        draft.tableConfigsUserActivities = action.tableConfigsUserActivities;
        break;
      case FETCH_USER_BY_EMAIL:
        draft.loadingUserByEmail = true;
        draft.errorUserByEmail = null;
        break;
      case FETCH_USER_BY_EMAIL_SUCCESS:
        draft.userByEmail = action.userByEmail;
        draft.loadingUserByEmail = false;
        break;

      case FETCH_ADDRESS_BY_ZIP_CODE:
        draft.loadingAddressByZipCode = true;
        draft.errorAddressByZipCode = null;
        break;
      case FETCH_ADDRESS_BY_ZIP_CODE_SUCCESS:
        draft.addressByZipCode = action.addressByZipCode;
        draft.loadingAddressByZipCode = false;
        break;

      case CLEAR_ITEMS_FORM:
        draft.addressByZipCode = initialState.addressByZipCode;
        draft.userByEmail = initialState.userByEmail;
        break;

      case FETCH_STATES:
        draft.loadingStates = true;
        draft.errorStates = null;
        break;
      case FETCH_STATES_SUCCESS:
        draft.states = action.states;
        draft.loadingStates = false;
        break;

      case FETCH_CITIES_BY_STATE:
        draft.loadingCities = true;
        draft.errorCities = null;
        break;
      case FETCH_CITIES_BY_STATE_SUCCESS:
        draft.cities = action.cities;
        draft.loadingCities = false;
        break;

      case FETCH_CATEGORIES_TYPES:
        draft.loadingCategories = true;
        draft.errorCategories = null;
        break;
      case FETCH_CATEGORIES_TYPES_SUCCESS:
        draft.categories = action.categories;
        draft.loadingCategories = false;
        break;

      case SET_FILTER:
        draft.filters = action.filters;
        break;
      case CLEAR_FILTER:
        draft.filters = initialState.filters;
        break;

      case SET_PAGINATION_CONFIGS:
        draft.paginationConfigs = action.paginationConfigs;
        break;
    }
  });

export default appLayoutReducer;
