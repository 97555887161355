import React from 'react';

import { Layout, Grid } from 'antd';
const { Content } = Layout;
const { useBreakpoint } = Grid;

const AuthLayout = ({ children }) => {
  const screens = useBreakpoint();

  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      <div
        style={{
          display: 'flex',
          height: '100vh',
        }}
      >
        <Content style={{ flex: '1' }}>
          <div>{children}</div>
        </Content>

        {/* {screens.md ? (
          <Content
            style={{
              // backgroundImage: 'url(/MPS.png)',
              // backgroundSize: 'cover',
              // backgroundPosition: 'center',
              backgroundColor: '#222',
            }}
          ></Content>
        ) : null} */}
      </div>
    </Layout>
  );
};

export default AuthLayout;
