import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb as Bread } from 'antd';
import React, { memo } from 'react';
import { Link } from 'react-router-dom';

const Breadcrumb = ({ route }) => (
  <>
    <Bread
      style={{
        marginBottom: '30px',
        display: 'flex',
        alignItens: 'center',
      }}
    >
      <Bread.Item href="/">
        <div
          style={{
            backgroundColor: '#ffca40',
            borderRadius: '50%',
            width: '20px',
            height: '20px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <HomeOutlined />
        </div>
      </Bread.Item>
      {route &&
        route.parent &&
        route.parent.map((r, index) => (
          <Bread.Item key={index}>
            {(r.path && <Link to={r.path}>{r.title}</Link>) || r.title}
          </Bread.Item>
        ))}
      {route && <Bread.Item>{route.title}</Bread.Item>}
    </Bread>
  </>
);

export default memo(Breadcrumb);
