import { Row, Col, Drawer, Spin, Skeleton, Button } from 'antd';
import { Formik } from 'formik';
import React, { memo, useState } from 'react';

import { Form, Input, SubmitButton } from 'components';
import { DRAWER_PROPS } from 'utils/layout/components/drawer';
import { ACTIVITIES_VALIDATION } from 'validations/activities';

import { LabelContainer, FormTitleContainer } from './ActivitiesForm.styles';

const ThemeCreateOrUpdate = ({
  product,
  loading = false,
  isOpen,
  onClose,
  onSave,
}) => {
  const [hasNumber, setHasNumber] = useState(false),
    [title, setTitle] = useState(product?.name);

  const titleFormProduct = () => {
    if (loading) return <Skeleton paragraph={{ rows: 1 }} />;
    return `${product?.id ? 'Editar' : 'Nova'} atividade`;
  };

  const handleTitleChange = e => {
    const regex = /\d/;
    setTitle(e.target.value);

    if (regex.test(e.target.value)) {
      setHasNumber(true);
      setTitle(title.replace(/\d/, ''));
    } else setHasNumber(false);
  };

  return (
    <>
      <Drawer
        title={titleFormProduct()}
        visible={isOpen}
        onClose={onClose}
        {...DRAWER_PROPS}
      >
        {!loading ? (
          <Formik
            enableReinitialize
            initialValues={{
              ...product,
            }}
            validateOnMount
            validationSchema={ACTIVITIES_VALIDATION}
            onSubmit={(values, { setSubmitting, resetForm }) => {
              const newValues = {
                ...values,
                name: title,
              };
              setTimeout(() => {
                if (onSave) onSave({ ...newValues });
                setSubmitting(false);
                resetForm();
              }, 800);
            }}
          >
            <Form layout="vertical">
              <FormTitleContainer>Informações básicas</FormTitleContainer>

              <Row>
                <Col md={24} style={{ marginBottom: '-30px' }}>
                  <Form.Item required name="name" label="Título do conteúdo">
                    <Input
                      onChange={handleTitleChange}
                      value={title}
                      name="name"
                      placeholder="Insira o título do treino"
                    />
                    {hasNumber && (
                      <small style={{ color: 'red', marginBottom: '10px' }}>
                        Não é possível números no título.
                      </small>
                    )}
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: '30px' }}>
                <Col md={24}>
                  <Form.Item required label="Valor de kcal/h" name="calories">
                    <Input
                      type="number"
                      name="calories"
                      min="0"
                      placeholder="Insira o valor de kcal/h"
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={[20, 0]} justify="end">
                <Col>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                    }}
                  >
                    <Button
                      style={{
                        border: '1px solid #E36926',
                        borderRadius: '8px',
                        marginRight: '15px',
                        color: '#E36926',
                      }}
                      onClick={() => onClose && onClose()}
                    >
                      Cancelar
                    </Button>
                    <SubmitButton showValidationErrors>Adicionar</SubmitButton>
                  </div>
                </Col>
              </Row>
            </Form>
          </Formik>
        ) : (
          <div style={{ textAlign: 'center' }}>
            <Spin size="large" />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default memo(ThemeCreateOrUpdate);
