/* eslint-disable react/no-array-index-key */
import { Checkbox } from 'antd';
import { Field } from 'formik';
import React from 'react';

export const CustomCheckbox = ({
  name,
  validate,
  children,
  onChange,
  ...props
}) => (
  <Field name={name} validate={validate}>
    {({ field: { value }, form: { setFieldValue } }) => (
      <Checkbox
        onChange={newValue => {
          setFieldValue(name, newValue.target.checked);
          if (onChange) onChange(newValue.target.checked);
        }}
        checked={value}
        {...props}
      >
        {children}
      </Checkbox>
    )}
  </Field>
);

CustomCheckbox.Group = ({ name, validate, onChange, ...props }) => (
  <Field name={name} validate={validate}>
    {({ field: { value }, form: { setFieldValue, setFieldTouched } }) => (
      <Checkbox.Group
        value={value}
        onChange={newValue => {
          setFieldValue(name, newValue);
          setFieldTouched(name, true, false);
          if (onChange) onChange(newValue);
        }}
        {...props}
      />
    )}
  </Field>
);

export default CustomCheckbox;
