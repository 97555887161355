import { notification } from 'antd';
import { extend } from 'umi-request';

import { useSession } from 'hooks';
import { removeAccess } from 'utils/authentication';
import history from 'utils/history';

const { getAccessToken } = useSession();

/**
 * Default response status messages.
 */

/**
 * Given an request error, notifies with an servver message or fallback one.
 *
 * @param {object} error The error response object
 */
const errorHandler = message => {
  const { response, data } = message;

  if (response?.status) {
    // Comentando as respostas do backend pois elas estão vindo em inglês
    const errorText = data.message;
    const { status } = response;
    if (response.status === 404 && data?.code === 209) {
      removeAccess();
      history.push('/system/login');
      notification.message({
        message: 'Erro de acesso',
        description: 'Sua sessão é inválida ou expirou',
      });
      throw new Error(errorText);
    } else if (errorText === 'Live Not Found') {
      return;
    } else {
      notification.error({
        message: '',
        description:
          errorText === 'Live Not Found'
            ? 'Não existem lives para serem carregadas'
            : errorText,
      });
      throw new Error(
        `Erro ${status}: ${errorText} \n \n Backend response: ${data.message}`,
      );
    }
  } else if (!response) {
    notification.error({
      message: 'Anomalia de rede',
      description: 'Sua rede está anormal e não pode se conectar ao servidor',
    });
  }

  throw message;
};

const request = (
  isLogin = false,
  baseUrl = process.env.REACT_APP_API_BASEURL,
) =>
  extend({
    prefix: baseUrl,
    headers: {
      'app-language': 'pt-br',
      'app-id': localStorage.getItem('applicationId'),
      Authorization: !isLogin ? `Bearer ${getAccessToken()}` : null,
    },
    errorHandler,
  });

export default request;
