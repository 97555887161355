import * as Sentry from '@sentry/browser';
import { ConfigProvider } from 'antd';
import ptBR from 'antd/es/locale/pt_BR';
import 'themes/antd-overrides.css';
import 'react-quill/dist/quill.snow.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'antd/dist/antd.css';
import 'moment/locale/pt-br';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'numbro/dist/languages.min';

import configureStore from 'configs/configureStore';
import GlobalStyles from 'themes/global-styles';
import { Environment } from 'utils/constants';
import history from 'utils/history';

import Router from './router';

const MOUNT_NODE = document.getElementById('app');

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    environment: process.env.NODE_ENV,
    release: `dashboard-mps@${process.env.npm_package_version}`,
  });
}

// Create redux store with history
const initialState = {};
const store = configureStore(initialState, history);

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={ptBR}>
      <Router history={history} />
    </ConfigProvider>
    <GlobalStyles />
  </Provider>,
  MOUNT_NODE,
);

// Install ServiceWorker and AppCache in the end since
// it's not most important operation and if main code fails,
// we do not want it installed
if (process.env.NODE_ENV === Environment.PRODUCTION) {
  require('offline-plugin/runtime').install(); // eslint-disable-line global-require
}
export { store, history };
