import { createBrowserHistory, createHashHistory } from 'history';

import { Environment } from 'utils/constants';

const history =
  process.env.NODE_ENV === Environment.PRODUCTION
    ? createBrowserHistory()
    : createHashHistory();

export default history;
